/*------------------------------------*\
    # plugins.ps-contract
\*------------------------------------*/

.ps-contract-overide {
	& #ps-clickwrap {
		.ps-contract {
			position: relative;

			padding: calc($unit * 8);

			border: 1px solid $grey-10;
			border-radius: calc($global-radial * 2);
			box-shadow: $shadow-sml;

			/* Title */
			& > .ps-contract-title {
				margin-bottom: calc($unit * 6);
				height: calc($unit * 10);

				font-size: calc($type-base-alt * 1px);
				line-height: calc($unit * 10);
				font-weight: calc($type-weight-medium);
				letter-spacing: -0.5px;
			}

			/* Download button */
			& > .ps-contract-options {
				position: absolute;
				top: calc($unit * 8);
				right: calc($unit * 8);

				padding: 0;
				background-color: transparent;
				border: 0;

				.ps-expand-button,
				.ps-download-link {
					height: calc($unit * 10);
					padding: 0 calc($unit * 4);
					font-size: calc($type-plus-small * 1px);
					line-height: calc($unit * 10);

					font-weight: $type-weight-medium;
					white-space: nowrap;
					color: $color-text;

					border: 1px solid $grey-10;
					border-radius: $global-rounded;
					background-color: transparent;
					transition: all 0.2s ease;

					cursor: pointer;

					&:hover {
						text-decoration: none;
						border: 1px solid $grey-60;
					}
				}

				.ps-expand-button {
					margin-right: calc($unit * 2);
					padding-left: calc($unit * 3);

					&:before {
						content: '';
						display: inline-block;
						vertical-align: -5px;
						width: calc($unit * 5);
						height: calc($unit * 5);
						margin-right: calc($unit * 1.5);
						mask-size: calc($unit * 5);
						background-size: calc($unit * 5);
						background-color: $color-text-light;
						mask-image: svg-load('../images/expand.svg');
					}
				}
			}

			/* Scrollable text */
			& > .ps-contract-body {
				padding: 0;
				border: 0;

				p {
					font-size: calc($type-plus-small * 1px);
					line-height: calc($unit * 5);
					color: $grey-80;

					& > strong {
						color: $color-secondary;
						font-weight: $type-weight-medium;
					}
				}
			}

			/* Footer */
			& > .ps-checkbox-container {
				&.ps-scroll-message {
					position: relative;

					padding: calc($unit * 3) 0;
					padding-top: calc($unit * 13);
					margin-top: -81px;

					font-size: calc($type-plus-small * 1px);
					line-height: calc($unit * 4);

					border: 0;
					background: linear-gradient(0deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
				}

				&.ps-checkbox-clickable {
					padding: calc($unit * 4);
					border: 0;
					border-radius: $global-radial;
					background-color: $color-navigation;

					& > .ps-checkbox {
						margin: 0;
						width: calc($unit * 4);
						height: calc($unit * 4);

						border: 1px solid $grey-20;
						border-radius: 2px;
					}
				}
			}
		}
	}
}
