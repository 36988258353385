/*------------------------------------*\
    # modules.list
\*------------------------------------*/

.list {
	list-style: none;

	&--primary {
		.item,
		& > li {
			position: relative;
			padding-left: calc($unit * 3);

			&:before {
				content: '';

				position: absolute;
				top: 7px;
				left: 0;

				display: block;
				width: 4px;
				height: 4px;
				background-color: $grey-60;

				border-radius: $global-rounded;
			}
		}
	}

	&--secondary {
		& > .item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: calc($unit * 4);
			margin-bottom: $unit;
			background-color: $color-navigation;
			border-radius: $global-radial;
		}
	}

	&--bordered {
		width: 100%;

		& > .item {
			display: flex;
			gap: calc($unit * 4);
			padding: calc($unit * 3) 0;

			&:not(:last-child) {
				border-bottom: 1px solid $grey-10;
			}

			@media (--screen-to-med) {
				flex-direction: column;
			}

			@media (--screen-from-med) {
				align-items: center;
				justify-content: space-between;
			}
		}
	}
}
