/*------------------------------------*\
    # components.notes
\*------------------------------------*/

.notes {
	display: flex;
	align-items: flex-start;
	gap: calc($unit * 3);
	padding: calc($unit * 4);

	font-size: calc($type-nano * 1px);
	line-height: calc($unit * 3.5);

	border-radius: $global-radial;

	/* Start icon */
	& > .icon {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: calc($unit * 8);
		height: calc($unit * 8);

		border-radius: $global-rounded;
		box-shadow: $shadow-tooltip;

		&:before {
			content: '';

			display: block;
			width: calc($unit * 4);
			height: calc($unit * 4);

			mask-size: calc($unit * 4);
			mask-repeat: no-repeat;
		}

		@media (--screen-to-med) {
			display: none;
		}
	}

	/* Types of Notes */
	&--primary {
		border: 1px solid color(#e5e9ed a(0.3));
		background: linear-gradient(96.56deg, #ecf5ff 9.61%, #e5edf4 33.83%, #ffffff 82.45%);

		& > .icon {
			background-color: $white;

			&:before {
				background-color: $color-primary;
				mask-image: svg-load('../images/regular/info.svg');
			}
		}
	}

	&--error {
		align-items: center;
		color: $white;
		background-color: $error;

		& > .icon {
			background-color: color($white a(0.32));

			&:before {
				background-color: $white;
				mask-image: svg-load('../images/error.svg');
			}
		}
	}

	&--warning {
		align-items: center;
		color: $white;
		background-color: $orange;

		&:has(.type--validation) {
			align-items: flex-start;
		}

		& > .icon {
			background-color: color($white a(0.32));

			&:before {
				background-color: $white;
				mask-image: svg-load('../images/info.svg');
				transform: rotate(180deg);
			}
		}

		.type--validation {
			button,
			a,
			strong {
				color: $white;
				font-size: calc($type-nano * 1px);
				line-height: calc($unit * 3.5);
				font-weight: $type-weight-regular;
			}
		}
	}

	&--default {
		background-color: $color-navigation;
	}
}
