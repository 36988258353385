/*------------------------------------*\
    # components.hardware
\*------------------------------------*/

$hardware-footer-height: 86px;
$hardware-sidebar-width: 300px;

.hardware {
	&__saved {
		position: absolute;
		right: 0;
		bottom: 94px;
		left: 0;
		z-index: 9999;

		overflow-y: auto;
		padding: calc($unit * 4);
		max-height: 430px;

		text-align: left;

		box-shadow: $shadow-big;
		background-color: white;

		@media (--screen-from-sml) {
			right: auto;
			bottom: 50px;
			left: 0;
			width: 410px;
			border-radius: $global-radial;
		}

		@media (--screen-from-med) {
			right: -102px;
			left: auto;
		}

		&.is-collapsed {
			transform: translateX(100%);

			@media (--screen-from-sml) {
				transform: translateX(calc(100% - 16px));
			}
		}

		&.hidden {
			display: none;
		}

		&__option {
			margin-top: calc($unit / 2);
			list-style: none;

			&__item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: nowrap;
				padding: $unit calc($unit * 2);
				margin-bottom: $unit;

				font-size: calc($type-tiny * 1px);
				line-height: calc($unit * 4);
				font-weight: $type-weight-medium;

				border-radius: $global-rounded;
				background-color: $color-navigation;
			}
		}
	}
}
