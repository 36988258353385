/*------------------------------------*\
    # view.membership
\*------------------------------------*/

.membership {
	min-height: 100vh;
	background-color: #fff;
	background-size: cover;

	&--main {
		position: relative;
		z-index: 0;
		padding-top: calc($unit * 10);
		padding-bottom: calc($unit * 7);
		min-height: 100vh;
		overflow-y: auto;

		@media (--screen-from-sml) {
			padding-top: 94px;
			padding-bottom: calc($unit * 10);
		}

		&__action {
			color: $color-text-light;
		}

		&__label {
			@media (--screen-to-sml) {
				display: none;
			}
		}

		&__btn {
			background-color: transparent;

			@media (--screen-to-sml) {
				width: 100%;
				height: calc($unit * 6);
				padding: 0 calc($unit * 3);

				line-height: calc($unit * 6);
				color: $color-text-dark;

				border: 1px solid $grey-20;
				border-radius: $global-radial;
			}

			@media (--screen-from-sml) {
				margin-top: 0;
				color: $color-primary;
				border: none;
			}
		}
	}

	&__section {
		position: relative;
		width: 100%;
		max-width: 511px;
		margin: 0 auto;
		padding: calc($unit * 3);
		border-radius: calc($global-radial * 2);

		@media (--screen-from-sml) {
			padding: calc($unit * 6);
			background-color: #fff;
			box-shadow: 0 20px 40px 0 rgba(107, 107, 107, 0.1);
		}

		@media (--screen-from-xlrg) {
			padding: calc($unit * 13);
		}

		@media (--screen-height-to-sml) {
			padding: calc($unit * 3);
		}

		/* Dots behind form */
		&:before {
			content: '';
			display: none;
			background-image: svg-load('../images/dots-bottom-left.svg');
			background-repeat: no-repeat;
			position: absolute;
			left: -70px;
			bottom: -60px;
			height: 250px;
			width: 250px;
			z-index: -1;

			@media (--screen-from-sml) {
				display: block;
			}
		}
	}

	&__title {
		margin-bottom: calc($unit * 2);
		@mixin type-scale $type-large, 1.5;
		font-weight: $type-weight-medium;

		@media (--screen-from-xlrg) {
			margin-bottom: calc($unit * 7);
			@mixin type-scale $type-xxlarge, 2;
		}

		@media (--screen-height-to-sml) {
			margin-bottom: calc($unit * 5);
		}
	}

	&__label {
		display: block;
		margin-bottom: calc($unit / 2);
		@mixin type-scale $type-small, 1;

		@media (--screen-from-sml) {
			margin-bottom: calc($unit * 1.5);
		}
	}

	/* Login */
	.loader--progress {
		top: 0;
	}
}
