/*------------------------------------*\
    # modules.btn
\*------------------------------------*/

.btn {
	/**
     * Button base
     */
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;

	touch-action: manipulation;
	user-select: none;

	cursor: pointer;
	white-space: nowrap;
	text-decoration: none;
	text-align: center;
	border: 0;
	border-radius: $global-rounded;
	transition: all 0.2s ease;

	-webkit-appearance: none;

	&:focus {
		outline: 0;
	}

	&:focus-visible {
		border-radius: $global-rounded;
		outline: 2px solid $color-primary;
		outline-offset: 2px;
	}

	&:disabled,
	button[disabled] {
		opacity: 0.2;
		cursor: default;
	}

	/**
     * Button base project styling
     */
	font-family: $ff-sans;
	font-weight: $type-weight-medium;
	text-transform: none;

	/**
     * Button sizes
     */
	&--sml {
		height: calc($unit * 8);
		font-size: calc($type-xsmall * 1px);
		line-height: calc($unit * 5);
		padding: 0 calc($unit * 3);

		&:not(.no-gap) {
			gap: calc($unit * 1.5);
		}
	}

	&--med {
		height: calc($unit * 10);
		padding: 0 calc($unit * 4);
		font-size: calc($type-plus-small * 1px);
		line-height: calc($unit * 10);

		&:not(.no-gap) {
			gap: calc($unit * 2);
		}

		&:has(.icon) {
			padding: 0 calc($unit * 3);
		}

		&--wicon {
			@media (--screen-from-sml) {
				padding: 0 calc($unit * 1.5);
			}
		}
	}

	&--lrg {
		@mixin type-scale $type-base, 2;
		height: calc($unit * 14);
		line-height: calc($unit * 14);
		padding: 0 calc($unit * 12);
	}

	/* Styles */
	&--primary {
		background: $color-primary;
		color: $white;
		border: 1px solid $color-primary;

		&:hover,
		&:focus {
			color: $white;
			background: color($color-primary blackness(10%));
		}

		&:focus-visible {
			border-radius: $global-rounded;
			outline: 2px solid $color-primary;
			outline-offset: 2px;
		}
	}

	&:disabled {
		opacity: 0.3;
		cursor: default;
	}

	&--secondary {
		color: $color-primary;
		border: 1px solid $color-primary;
		background-color: transparent;

		&:hover {
			background-color: hsla(
				var(--hue-primary-final),
				var(--saturation-primary-final),
				var(--lightness-primary-final),
				0.1
			);
		}

		&:focus-visible {
			border-radius: $global-rounded;
			outline: 2px solid $color-primary;
			outline-offset: 2px;
		}

		&:disabled,
		button[disabled] {
			&:hover {
				opacity: 0.2;
				cursor: default;
			}
		}
	}

	&--tertiary {
		color: $color-text;
		border: 1px solid $grey-10;
		background-color: transparent;

		&:hover {
			border: 1px solid $grey-60;
		}

		&:disabled,
		button[disabled] {
			color: $grey-40;
			border: 1px solid $grey-10;
			cursor: default;

			&:hover {
				color: $grey-40;
				border: 1px solid $grey-10;
				cursor: default;
			}

			i {
				opacity: 0.5;
			}
		}
	}

	&--link {
		padding: 0;
		color: $color-primary;
		background-color: transparent;
		border-radius: 0;

		&:disabled,
		&.is-disabled {
			opacity: 0.5;
			background-color: transparent;
			border: 0;
			pointer-events: none;

			&:hover {
				background-color: transparent;
				border: 0;
				cursor: not-allowed;
			}
		}

		&:focus-visible {
			border-radius: $global-rounded;
			outline: 2px solid $color-primary;
			outline-offset: 2px;
		}

		&--underline {
			text-decoration: underline;
		}

		&--tertiary {
			color: $grey-80;

			&:hover {
				color: $color-text;
			}

			&:focus-visible {
				border-radius: $global-rounded;
				outline: 2px solid $grey-80;
				outline-offset: 2px;
			}

			&:active {
				border-color: $color-primary;
			}
		}

		&--error {
			color: $error;

			&:hover {
				color: color($error lightness(70%));

				& > .icon {
					background-color: color($error lightness(70%));
				}
			}

			a {
				color: $error;
			}
		}
	}

	&--icon {
		padding: 0;
		background-color: transparent;
		border-radius: calc($global-radial / 2);

		&:hover {
			background-color: #e5e9ed;
		}

		&:disabled {
			opacity: 0.5;
			cursor: default;
			background-color: transparent;
		}

		&.is-active {
			background-color: $grey-50;

			& > .icon {
				background-color: $white;
			}
		}
	}

	/* Action buttons are used within side bars or in the header of modal windows. */
	&--action {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: calc($unit * 10);
		height: calc($unit * 10);

		&--secondary {
			color: $color-text;
			border: 1px solid $grey-10;
			background-color: $white;

			&:hover,
			&.is-active {
				border-color: $grey-50;
			}

			&:focus-visible {
				border-radius: $global-rounded;
				outline: 2px solid $color-text;
				outline-offset: 2px;
			}
		}
	}

	&--sidebar-toggle {
		position: fixed;
		top: calc($unit * 6.5);
		left: 229px;
		z-index: 5;

		padding: 0;

		background-color: transparent;
		transition: all 250ms ease-in-out;

		&:focus-visible {
			border-radius: $global-radial;
			outline: 2px solid $color-text;
			outline-offset: -6px;
		}

		@media (--screen-to-xlrg) {
			top: calc($unit * 4.5);
			right: calc($unit * 3);
			left: auto;

			width: calc($unit * 10);
			height: calc($unit * 10);

			border: 1px solid $grey-10;
			background-color: white;
		}

		&.is-collapsed {
			left: 51px;

			@media (--screen-to-xlrg) {
				right: calc($unit * 3);
				left: auto;
			}
		}

		&.sidebar-is-expanded {
			@media (--screen-to-xlrg) {
				z-index: 2;
			}
		}

		& > .icon {
			margin-right: 0;
			width: calc($unit * 4);
			height: calc($unit * 4);
			mask-size: calc($unit * 4);

			&.menu-expand {
				background-color: $color-text;
				mask-image: svg-load('../images/menu-expand.svg');

				@media (--screen-from-xlrg) {
					background-color: $color-text-light;
				}
			}

			&.menu-collapse {
				background-color: $color-text;
				mask-image: svg-load('../images/close.svg');

				@media (--screen-from-xlrg) {
					background-color: $color-text-light;
					mask-image: svg-load('../images/menu-collapse.svg');
				}
			}
		}
	}

	&--addon {
		gap: $unit;
		height: calc($unit * 5);
		padding-left: calc($unit * 1.5);
		padding-left: calc($unit * 2);

		font-size: calc($type-xsmall * 1px);
		line-height: calc($unit * 6);
		color: $white;

		border-radius: $global-rounded;
		background-color: color($color-primary blackness(+10%));

		&:hover {
			background-color: color($color-primary blackness(0%));
		}
	}

	&--note {
		position: relative;
		width: calc($unit * 8);
		height: calc($unit * 8);
		background-color: transparent;

		&.has-note {
			&:before {
				content: '';

				position: absolute;
				top: 0;
				right: 0;

				display: block;
				width: $unit;
				height: $unit;

				border-radius: $global-rounded;
				background-color: $color-primary;
			}
		}
	}
}
