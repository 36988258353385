/*------------------------------------*\
    # components.auth
\*------------------------------------*/

.auth {
	height: 100vh;

	@media (--screen-to-xlrg) {
		display: flex;
		flex-direction: column;
	}

	@media (--screen-from-xlrg) {
		display: grid;
		grid-template-columns: 488px 1fr;
	}

	&:has(.auth__main__info) {
		display: block;

		.auth__main__info__header {
			display: block;
			width: 100%;
			padding: calc($unit * 6) calc($unit * 4);

			@media (--screen-from-med) {
				padding: calc($unit * 12) calc($unit * 16);
			}

			& > .auth__sidebar__logo {
				width: 120px;
			}
		}

		& > .auth__main {
			padding: 0;
		}

		& > .auth__sidebar {
			display: none;
		}
	}

	&__sidebar {
		@media (--screen-to-xlrg) {
			text-align: center;
			padding: calc($unit * 7) calc($unit * 2.5);
		}

		@media (--screen-from-xlrg) {
			position: relative;

			padding: calc($unit * 8) calc($unit * 10);

			background-color: #f8f8f8;
			background-image: url('../../img/abstract-background.png');
			background-size: 800px;
			background-repeat: no-repeat;
			background-position: bottom right -80px;

			/*
            Used for changing color of illustration
            based on selected primary color (cobranding)
            */
			&:after {
				content: '';

				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				display: block;
				background-color: $color-primary;
				mix-blend-mode: color;
			}
		}

		&__logo {
			width: 90px;

			@media (--screen-from-xlrg) {
				position: relative;
				z-index: 2;

				width: 150px;
			}
		}
	}

	&__main {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		flex-grow: 1;
		gap: calc($unit * 6);
		padding: calc($unit * 2.5) calc($unit * 7);

		@media (--screen-from-xlrg) {
			overflow-y: auto;
			padding: calc($unit * 5.5);
		}

		& > .loader--progress {
			top: 0;
		}

		&__info {
			width: 100%;
			margin-bottom: 70px;
			padding: 0 calc($unit * 4);

			@media (--screen-from-med) {
				max-width: 650px;
				margin-bottom: 140px;
			}
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		flex-grow: 1;
		width: 100%;
		max-width: 442px;

		&__title {
			margin-bottom: calc($unit * 6);
			@mixin type-scale $type-xxlarge, 2;
			font-weight: $type-weight-medium;
			letter-spacing: -0.5px;
			color: $color-text-dark;

			@media (--screen-from-xlrg) {
				@mixin type-scale $type-big, 2.5;
			}
		}

		&__description {
			@mixin type-scale $type-plus-small, 1;
			margin-bottom: calc($unit * 12);
		}

		&__create-pass {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: $unit;

			@mixin type-scale $type-plus-small, 1;
			font-weight: $type-weight-medium;
		}
	}
}
