/*------------------------------------*\
    # components.badge
\*------------------------------------*/

.badge {
	display: inline-flex;
	align-items: center;
	gap: $unit;
	padding: calc($unit / 2) calc($unit * 2);
	white-space: nowrap;

	font-size: calc($type-xsmall * 1px);
	line-height: calc($unit * 4);
	font-weight: $type-weight-medium;

	border: 0;
	border-radius: $global-rounded;
	outline: 0;

	/* Type of badge */
	&--start-icon {
		&:before {
			content: '';

			display: inline-flex;
			width: calc($unit * 3);
			height: calc($unit * 3);
			flex-shrink: 0;

			border-radius: $global-rounded;
			background-size: calc($unit * 3);
		}

		/* Icons based on status */
		&.badge--success {
			&:before {
				background-image: svg-load('../images/regular/check.svg');
			}
		}

		&.badge--error {
			&:before {
				background-image: svg-load('../images/regular/error.svg');
			}
		}

		&.badge--default {
			&:before {
				background-image: svg-load('../images/regular/error-grey.svg');
			}
		}
	}

	&--filled {
		text-transform: uppercase;
		border-radius: calc($global-radial / 2);

		&--primary {
			color: $white;
			background-color: $color-primary;
		}
	}

	/* Status */
	&--success {
		color: $success;
		background-color: $success-light;

		&:before {
			background-color: $success;
		}
	}

	&--error {
		color: $error;
		background-color: $error-light;

		&:before {
			background-color: $error;
		}
	}

	&--warning {
		color: #f1a326;
		background-color: #fdf3e2;

		&:before {
			background-color: #f1a326;
		}
	}

	&--default {
		color: $color-text-light;
		background-color: $grey-10;

		&:before {
			background-color: $color-text-light;
		}
	}
}
