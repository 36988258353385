/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.w {
	&--42 {
		width: 42px;
	}

	&--90 {
		width: 90px;
	}

	&--100 {
		width: 100px;
	}

	&--108 {
		width: 108px;
	}

	&--120 {
		width: 120px;
	}

	&--130 {
		width: 130px;
	}

	&--170 {
		width: 170px;
	}

	&--200 {
		width: 200px;
	}

	&--220 {
		width: 220px;
	}

	&--270 {
		width: 270px;
	}

	&--300 {
		width: 300px;
	}

	&--324 {
		width: 324px;
	}

	&--520 {
		width: 520px;
	}

	&--790 {
		width: 790px;
	}

	&--max {
		&--300 {
			width: 100%;
			max-width: 300px;
		}

		&--440 {
			width: 100%;
			max-width: 440px;
		}

		&--520 {
			width: 100%;
			max-width: 520px;
		}

		&--790 {
			width: 100%;
			max-width: 790px;
		}

		&--880 {
			width: 100%;
			max-width: 880px;
		}

		&--970 {
			max-width: 970px;
		}
	}
}

.fullwidth {
	width: 100%;
}
