/*------------------------------------*\
    # plugins.reactselect
\*------------------------------------*/

.react-select-container {
	/* Select input */
	& > .react-select__control {
		min-height: calc($unit * 11);

		font-size: calc($type-plus-small * 1px);

		border-color: $grey-20;
		border-radius: $global-radial;
		cursor: pointer;

		&:hover {
			border-color: $grey-50;
		}

		&.react-select__control--is-disabled {
			color: $grey-50;
			border-color: $grey-20;
			background-color: $grey-10;
			cursor: default;
		}

		&--menu-is-open {
			border-color: $color-primary;
			box-shadow: none;

			&:hover {
				border-color: $color-primary;
			}
		}

		/* Value */
		& > .react-select__value-container {
			height: 100%;
			padding: $unit calc($unit * 3);

			&--is-multi {
				display: flex;
				gap: $unit;
			}

			& > .react-select__placeholder {
				color: $grey-50;
			}

			& > .react-select__single-value {
				margin: 0;
				color: $color-text;

				& > .grid__creditcard {
					margin-right: calc($unit * 2);
				}
			}

			& > .react-select__multi-value {
				margin: 0;
				height: calc($unit * 8);
				border-radius: calc($global-radial / 2);

				& > .react-select__multi-value__label {
					font-size: calc($type-xsmall * 1px);
					line-height: calc($unit * 4);
					padding: calc($unit * 2) calc($unit * 2.5);
					padding-right: 0;
				}

				& > .react-select__multi-value__remove {
					padding: calc($unit * 2) calc($unit * 3);
					padding-left: calc($unit * 1.5);

					&:hover {
						background-color: transparent;
					}

					& > svg {
						display: none;
					}

					&:after {
						content: '';

						position: relative;

						display: inline-block;
						background-repeat: no-repeat;
						background-position: center;

						width: calc($unit * 3);
						mask-size: calc($unit * 3);
						aspect-ratio: 1;

						background-size: calc($unit * 3);
						background-image: svg-load('../images/close.svg');
					}
				}
			}

			& > div {
				&:has(.react-select__input) {
					display: inline-flex;
					align-items: center;
					justify-content: flex-start;
					gap: $unit;
					height: calc($unit * 8);
					margin: 0;
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}

		/* Chevron icon */
		& > .react-select__indicators {
			padding: 0 calc($unit * 3);

			& > .react-select__dropdown-indicator {
				height: calc($unit * 4);
				width: calc($unit * 4);

				mask-image: svg-load('../images/chevron-down.svg');
				mask-size: calc($unit * 4);
				background-color: $color-text;
				mask-position: center;

				& > svg {
					display: none;
				}
			}

			& > .react-select__clear-indicator {
				height: calc($unit * 3);
				width: calc($unit * 3);

				background-image: svg-load('../images/close.svg');
				background-size: calc($unit * 3);
				background-position: center;

				& > svg {
					display: none;
				}
			}
		}
	}

	/* Dropdown list */
	& > .react-select__menu {
		z-index: 2;

		overflow: hidden;
		border: 1px solid $grey-10;
		border-radius: calc($global-radial * 2);
		box-shadow: $shadow-big;

		.react-select__menu-list,
		& > div {
			margin: calc($unit * 2);
		}

		.react-select__option {
			overflow: hidden;
			white-space: nowrap;
			padding: calc($unit * 2);
			width: 100%;

			font-size: calc($type-plus-small * 1px);
			line-height: calc($unit * 5);
			text-overflow: ellipsis;

			border-radius: $global-radial;

			cursor: pointer;

			&:hover {
				background-color: $grey-10;
			}

			&.react-select__option--is-selected {
				color: $color-text;
				background-color: var(--color-sidebar-final);
			}

			& > .grid__creditcard {
				margin-right: calc($unit * 2);
			}
		}
	}

	/* Sizes (44px of height is default) */
	&--sml {
		& > .react-select__control {
			height: calc($unit * 8);
			line-height: calc($unit * 7.5);
		}
	}

	&--300 {
		max-width: 300px;
		width: 100%;
	}
}

.react-select__indicator-separator {
	display: none;
}
