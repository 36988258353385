/*------------------------------------*\
    # components.layout
\*------------------------------------*/

.addon {
	display: table;
	border-collapse: collapse;
	width: 100%;
	background-color: white;
	box-shadow: $shadow-big;
	table-layout: fixed;

	&--popup {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		padding: calc($unit * 4);
		padding: calc($unit * 3);

		border: 1px solid $grey-10;
		border-radius: 12px;
	}
}
