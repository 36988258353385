/*------------------------------------*\
    # components.equipment
\*------------------------------------*/

.equipment {
	&__details {
		&__table {
			border: 1px solid $grey-10;
			display: flex;
			flex-wrap: wrap;

			&__item {
				width: 100%;
				position: relative;
				padding: calc($unit * 3.5);
				border-bottom: 1px solid $grey-10;

				@media (--screen-from-med) {
					width: 50%;
				}

				&.border {
					&:after {
						content: '';
						position: absolute;
						right: 0;
						top: calc($unit * 3.5);
						bottom: calc($unit * 3.5);
						border-right: 1px solid $grey-10;

						@media (--screen-to-med) {
							display: none;
						}
					}
				}
			}
		}

		&__aside {
			position: sticky;
			top: calc($unit * 0);

			&__card {
				overflow: hidden;
				border-radius: calc($global-radial * 1.5) calc($global-radial * 1.5) 0 0;
			}

			&__body {
				margin-bottom: calc($unit * 4);
				border: 1px solid $grey-10;
				border-top: 0;
				border-radius: 0 0 calc($global-radial * 1.5) calc($global-radial * 1.5);
			}

			&__row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: calc($unit * 4);
				padding: calc($unit * 4) calc($unit * 6);

				border-bottom: 1px solid $grey-10;

				&:last-of-type {
					border-bottom: 0;
				}

				&.total {
					padding: calc($unit * 6);
				}
			}
		}
	}

	&__list {
		position: relative;
		display: flex;
		flex-wrap: nowrap;

		&.is-scrollable {
			overflow: auto;
		}

		&--pos {
			display: grid;
			grid-template-columns: 200px repeat(4, minmax(200px, 1fr)) repeat(2, 80px);

			@media (--screen-from-med) {
				grid-template-columns: 300px repeat(4, minmax(200px, 1fr)) repeat(2, 80px);
			}

			@media (--screen-from-xxxlrg) {
				padding-right: 0;

				.equipment__list__item {
					&.select {
						display: flex;
					}
				}
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			flex-shrink: 0;
			order: 2;
			border-bottom: 1px solid $grey-10;

			&.name {
				width: 190px;
				flex-grow: 1;
			}

			&.option {
				width: 167px;
			}

			&.agent-cost {
				width: 100px;
			}

			&.merchant-cost {
				width: 130px;
			}

			&.quantity {
				width: 140px;
			}

			&.total-price {
				width: 100px;
			}

			&.schedule {
				width: 160px;
			}

			&.shipping {
				width: 160px;
			}

			&.shipping-speed {
				width: 160px;
			}

			&.note {
				width: 60px;

				& > .equipment__list__th,
				& > .equipment__list__td {
					justify-content: center;
				}
			}

			&.select {
				width: 50px;

				& > .equipment__list__th,
				& > .equipment__list__td {
					justify-content: center;
				}
			}

			&--expanded {
				grid-area: 2 / 1 / 3 / 8;
				padding: calc($unit * 4);
				background-color: $color-navigation;

				& > .item {
					display: grid;
					align-items: center;
					gap: calc($unit * 2);
					grid-template-columns: 288px 1fr;
					padding: calc($unit * 2) 0;

					&:not(:last-child) {
						border-bottom: 1px solid $grey-10;
					}
				}
			}
		}

		&__th {
			display: flex;
			align-items: center;
			height: calc($unit * 10);
			padding: 0 calc($unit * 3);

			font-size: calc($type-nano * 1px);
			line-height: calc($unit * 10);
			font-weight: $type-weight-semibold;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			white-space: nowrap;
			color: $color-text-light;

			background-color: $color-navigation;

			[data-tooltip]:before {
				text-transform: initial;
				white-space: normal;
			}

			&.right {
				justify-content: flex-end;
			}
		}

		&__td {
			display: flex;
			padding: calc($unit * 3);
			justify-content: flex-start;
			align-items: center;
			flex-grow: 1;
			word-break: break-word;

			font-size: calc($type-plus-small * 1px);
			line-height: calc($unit * 5);

			&.right {
				justify-content: flex-end;
			}

			&.top {
				align-items: start;
			}

			.btn--link {
				width: 100%;
				white-space: normal;
				justify-content: flex-start;
				text-align: left;
			}

			& > .equipment-card__thumbnail {
				flex-shrink: 0;
				width: 52px;
				aspect-ratio: 1;
				padding-top: 0;
				margin-right: calc($unit * 3);
				margin-bottom: 0;

				&:before,
				&:after {
					display: none;
				}
			}
		}
	}

	&__footer {
		position: sticky;
		bottom: 0;
		z-index: 3;

		display: grid;
		grid-template-columns: 120px 1fr;
		gap: calc($unit * 3);
		padding: calc($unit * 3) calc($unit * 4);
		margin-top: auto;
		margin-left: calc(-$unit * 4);
		margin-right: calc(-$unit * 4);

		border-top: 1px solid $color-navigation;
		background-color: $white;

		@media (--screen-to-lrg) {
			box-shadow: 0 calc(-$unit * 3) calc($unit * 4) 0px color($grey-100 a(0.12));
		}

		@media (--screen-from-sml) {
			grid-template-columns: 220px 1fr;
			padding: calc($unit * 3) calc($unit * 12);
		}

		@media (--screen-from-med) {
			margin-left: calc(-$unit * 6);
			margin-right: calc(-$unit * 6);
		}

		@media (--screen-from-lrg) {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-left: calc(-$unit * 12);
			margin-right: calc(-$unit * 12);
		}

		@media (--screen-from-xxlrg) {
			margin-left: calc(-$unit * 12);
			margin-right: calc(-$unit * 12);
		}

		&.is-modal {
			position: relative;
			left: unset;

			flex-grow: 1;
			padding: 0;
			margin-left: 0;
			margin-right: 0;

			background-color: transparent;
		}

		&.fullwidth {
			left: 0;
		}

		&__saved {
			&__wrapper {
				position: relative;

				@media (--screen-to-sml) {
					width: 100%;
				}

				@media (--screen-from-lrg) {
					margin-right: auto;
					width: 230px;
				}
			}

			&__select {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: $unit;
				width: 100%;
				height: calc($unit * 11);
				padding: 0 calc($unit * 3);

				font-size: calc($type-plus-small * 1px);
				line-height: calc($unit * 11);
				white-space: nowrap;
				color: $grey-50;

				border: 1px solid $grey-20;
				border-radius: calc($global-radial * 1.5);
				background-color: $white;

				@media (--screen-to-sml) {
					font-size: calc($type-xsmall * 1px);
				}
			}
		}

		&__summary {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: calc($unit * 3);
		}

		&__actions {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: calc($unit * 2);

			& > .btn {
				flex-grow: 1;
			}

			@media (--screen-to-lrg) {
				grid-area: 2 / 1 / 3 / 3;
			}
		}
	}

	&__hardware {
		display: flex;
		flex-direction: column;
		gap: calc($unit * 5);
		margin-bottom: calc($unit * 6);

		@media (--screen-from-lrg) {
			display: grid;
			grid-template-columns: 250px 1fr;
			gap: calc($unit * 5);
		}

		&.equipment-details {
			@media (--screen-to-xlrg) {
				display: flex;
				flex-direction: column;
				gap: calc($unit * 6);
			}

			@media (--screen-from-xlrg) {
				grid-template-columns: 1fr 340px;
				gap: calc($unit * 10);
			}

			@media (--screen-from-xxlrg) {
				gap: 110px;
			}
		}

		&__navigation {
			z-index: 2;

			& > .tabs--vertical__wrapper {
				position: sticky;
				top: 0;
			}
		}

		&__list {
			display: grid;
			grid-template-columns: 1fr;
			gap: calc($unit * 5) calc($unit * 6);

			@media (--screen-from-sml) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media (--screen-from-lrg) {
				grid-template-columns: 1fr;
			}

			@media (--screen-from-xlrg) {
				grid-template-columns: repeat(3, 1fr);
			}

			@media (--screen-from-xxlrg) {
				grid-template-columns: repeat(4, 1fr);
			}

			&--modal {
				grid-template-columns: 1fr;

				@media (--screen-from-med) {
					grid-template-columns: repeat(3, 1fr);
				}
			}
		}
	}

	&__saved {
		position: absolute;
		bottom: calc(100% + 16px);
		left: 0;

		overflow-y: auto;
		max-height: 290px;

		border: 1px solid $grey-10;
		border-radius: $global-radial;
		background-color: $white;
		box-shadow: $shadow-big;

		&:not(.is-expanded) {
			display: none;
		}

		@media (--screen-to-sml) {
			width: 300px;
		}

		@media (--screen-from-sml) {
			width: 410px;
		}

		&__header {
			padding: calc($unit * 4);
		}
	}
}
