/*------------------------------------*\
    # components.nav
\*------------------------------------*/

.nav {
	display: flex;
	flex-direction: column;
	gap: calc($unit * 3);
	padding: 0 calc($unit * 5);
	padding-top: $unit;

	&__item {
		position: relative;
	}

	&__link {
		position: relative;

		display: flex;
		align-items: center;
		white-space: nowrap;
		padding: calc($unit * 2);

		color: $white;

		border-radius: $global-rounded;
		cursor: pointer;

		&:hover {
			& > .icon {
				opacity: 1;
			}
		}

		&:focus {
			color: $white;
			opacity: 0.85;
		}

		&:focus-visible {
			border-radius: $global-rounded;
			outline: 2px solid $color-text;
			outline-offset: 2px;
		}

		&.is-collapsed {
			height: calc($unit * 9);
			padding: 0 calc($unit * 2);
			justify-content: center;

			&:hover {
				background-color: $grey-10;

				.nav__link__text {
					opacity: 1;
					visibility: visible;

					position: absolute;
					left: calc(100% + 10px);

					display: block;
					padding: 0 calc($unit * 2);
					margin-left: 0;

					font-size: calc($type-xsmall * 1px);
					line-height: calc($unit * 6);
					color: $grey-80;

					border-radius: $global-rounded;
					background-color: $grey-10;
				}
			}

			&.is-active {
				&:hover {
					.nav__link__text {
						color: $white;
						background-color: $color-primary;
					}
				}
			}

			.nav__link__text {
				@media (--screen-from-med) {
					opacity: 0;
					display: none;
				}
			}

			.icon--chevron--down,
			.icon--chevron--top--white {
				display: none;
			}

			.nav__image {
				@media (--screen-from-xlrg) {
					opacity: 0;
					visibility: hidden;
				}
			}

			.nav__link__button {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(50%, -50%);

				padding: 0;
				width: calc($unit * 4);
				height: calc($unit * 4);

				& > .text {
					display: none;
				}
			}

			.nav__link__padding--customers,
			.nav__link__padding--transactions {
				padding-right: calc($unit * 3);
			}
		}

		&.is-active {
			opacity: 1;
			position: relative;
			background-color: $color-primary;

			.nav__link__text {
				color: $white;
			}

			&:hover {
				background-color: color($color-primary blackness(5%));
			}

			&:focus-visible {
				border-radius: $global-rounded;
				outline: 2px solid $color-text;
				outline-offset: 2px;
			}

			.icon {
				opacity: 1;
				z-index: 2;
				background-color: $white;
			}
		}

		/* Used for pages with shortcut button */
		&.shortcut {
			&:hover {
				& > a {
					& > .icon {
						opacity: 1;
					}
				}
			}

			& > a {
				display: flex;
				align-items: center;
				flex-grow: 1;
			}

			&.is-collapsed {
				& > a {
					justify-content: center;
				}
			}
		}

		& > .icon {
			opacity: 0.5;
			background-color: $color-text;
		}

		& > a {
			&:focus-visible {
				border-radius: $global-rounded;
				outline: 2px solid $color-text;
				outline-offset: 2px;
			}
		}

		.nav__link__button {
			top: calc($unit * 2);
			right: calc($unit * 2);
		}

		&__text {
			z-index: 2;

			flex-grow: 1;
			margin-left: calc($unit * 3.5);

			@mixin type-scale $type-plus-small, 1;
			font-weight: $type-weight-medium;
			color: $color-text;
		}
	}

	&__icon {
		opacity: 0.5;
		flex-shrink: 0;
		mask-repeat: no-repeat;
		background-color: $color-navigation;

		/* Icons */
		&--view {
			mask-image: svg-load('../images/view.svg');
		}

		&--customers {
			mask-image: svg-load('../images/customers.svg');
		}

		&--eapps {
			mask-image: svg-load('../images/eapps.svg');
		}

		&--plus-sqare {
			mask-image: svg-load('../images/plus-sqare.svg');
		}

		&--receipt {
			mask-image: svg-load('../images/receipt.svg');
		}

		&--equipment {
			mask-image: svg-load('../images/equipment.svg');
		}

		&--chart {
			mask-image: svg-load('../images/chart.svg');
		}

		&--swap {
			mask-image: svg-load('../images/swap.svg');
		}

		&--layout {
			mask-image: svg-load('../images/layout.svg');
		}

		&--settings {
			mask-image: svg-load('../images/settings.svg');
		}

		&--reports {
			mask-image: svg-load('../images/reports.svg');
		}
	}

	&--secondary {
		display: flex;
		flex-direction: column;
		gap: calc($unit * 3);

		&__link {
			display: flex;
			align-items: center;
			gap: calc($unit * 2);
			padding: 0 calc($unit * 3);
			width: 100%;

			font-size: calc($type-plus-small * 1px);
			font-weight: $type-weight-medium;
			line-height: calc($unit * 9);
			text-align: left;
			color: $grey-80;

			border: 0;
			border-radius: $global-rounded;
			background-color: transparent;
			transition: all 250ms ease-in-out;

			&:hover {
				color: $grey-80;
				background-color: #d9e3ff;
			}

			&.is-active {
				color: $color-primary;
				background-color: #d9e3ff;
			}
		}
	}
}
