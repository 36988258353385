/*------------------------------------*\
    # components.checkout
\*------------------------------------*/

.checkout {
	&__img {
		padding-top: 100%;
		width: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		&__thumb {
			width: 100px;
			height: 100px;
			padding-top: 0;
			display: inline-block;
			vertical-align: middle;

			&--70 {
				width: 70px;
				height: 70px;
			}

			&--36 {
				width: 36px;
				height: 36px;
			}
		}
	}

	&__list {
		padding-top: calc($unit * 2);

		&__addon {
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			width: 100%;
			padding: calc($unit * 3) calc($unit * 2);

			border-top: 1px solid $grey-10;

			& + .checkout__list__item {
				padding-top: 0;
				border-top: 0;
			}

			&__value {
				@mixin type-scale $type-medium;
				font-weight: $type-weight-semibold;
				text-align: right;
				margin-left: calc($unit * 1.5);
				max-width: 50%;

				.input {
					@mixin type-scale $type-medium;
					background-color: white;
					cursor: default;
					min-width: calc($unit * 7);

					&:hover,
					&:focus {
						border-color: $grey-10;
					}
				}

				&.is-total {
					@mixin type-scale $type-xlarge;
				}
			}
		}
	}
}

.checkout__list__addon:last-of-type {
	border-bottom: 0;
}
