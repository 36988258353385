/*------------------------------------*\
    # util.spacers
\*------------------------------------*/

/*custom multiplyer*/
$custom: 15;

.spc {
	/* MARGIN TOP */
	&--top {
		&--tny {
			margin-top: $unit;
		}

		&--sml {
			margin-top: calc($unit * 2);

			&--alt {
				margin-top: calc($unit * 3);
			}
		}

		&--med {
			margin-top: calc($unit * 4);
		}

		&--lrg {
			margin-top: calc($unit * 6);
		}

		&--xlrg {
			margin-top: calc($unit * 8);
		}

		&--xxlrg {
			margin-top: calc($unit * 10);
		}
	}

	/* MARGIN RIGHT */
	&--right {
		&--tny {
			margin-right: $unit;
		}

		&--sml {
			margin-right: calc($unit * 2);

			&--alt {
				margin-right: calc($unit * 3);
			}
		}

		&--med {
			margin-right: calc($unit * 4);
		}

		&--lrg {
			margin-right: calc($unit * 6);
		}
	}

	/* MARGIN BOTTOM */
	&--bottom {
		&--tny {
			margin-bottom: $unit;
		}

		&--sml {
			margin-bottom: calc($unit * 2);

			&--alt {
				margin-bottom: calc($unit * 3);
			}
		}

		&--med {
			margin-bottom: calc($unit * 4);
		}

		&--lrg {
			margin-bottom: calc($unit * 6);

			&--alt {
				margin-bottom: calc($unit * 7);
			}
		}

		&--xlrg {
			margin-bottom: calc($unit * 8);
		}

		&--xxlrg {
			margin-bottom: calc($unit * 10);
		}

		&--xxxlrg {
			margin-bottom: calc($unit * 12);
		}
	}

	/* MARGIN LEFT */
	&--left {
		&--tny {
			margin-left: $unit;
		}

		&--xsml {
			margin-left: calc($unit * 1.5);
		}

		&--sml {
			margin-left: calc($unit * 2);

			&--alt {
				margin-left: calc($unit * 3);
			}
		}

		&--med {
			margin-left: calc($unit * 4);
		}

		&--lrg {
			margin-left: calc($unit * 6);
		}

		&--xlrg {
			margin-left: calc($unit * 8);
		}

		&--xxlrg {
			margin-left: calc($unit * 10);
		}

		&--xxxlrg {
			margin-left: calc($unit * 12);
		}
	}
}
