/*------------------------------------*\
    # components.wizard
\*------------------------------------*/

.wizard {
	&--primary {
		display: flex;
		align-items: flex-start;
		width: 100%;

		&__item {
			position: relative;

			display: flex;
			flex-direction: column;
			align-items: center;
			gap: calc($unit * 4);
		}

		&__card {
			opacity: 0.5;

			display: flex;
			align-items: center;
			justify-content: center;
			width: calc($unit * 14);
			height: calc($unit * 14);
			border-radius: $global-radial;
			background-color: $color-navigation;

			& + .wizard--primary__title {
				display: none;
			}

			&.is-active {
				opacity: 1;
				box-shadow: $shadow-big;

				& + .wizard--primary__title {
					display: block;
				}
			}
		}

		&__title {
			position: absolute;
			top: calc(100% + 16px);
			left: 50%;
			transform: translateX(-50%);

			white-space: nowrap;

			font-size: calc($type-nano * 1px);
			line-height: calc($unit * 3);
			font-weight: $type-weight-semibold;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: $color-text-light;
		}

		&__separator {
			margin: 0 calc($unit * 2);
			margin-top: calc($unit * 7);
			height: 1px;
			flex-grow: 1;

			background-color: $grey-10;

			@media (--screen-from-med) {
				margin: 0 calc($unit * 6);
				margin-top: calc($unit * 7);
			}
		}

		/* Customs */
		&--leads {
			margin-bottom: calc($unit * 18);

			@media (--screen-to-xlrg) {
				margin-bottom: calc($unit * 14);
				padding-top: calc($unit * 14);
			}
		}

		&--go-eapp {
			margin-bottom: calc($unit * 20);
		}
	}
}
