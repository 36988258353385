/*------------------------------------*\
    # view.settings
\*------------------------------------*/

.settings {
	@media (--screen-from-med) {
		display: grid;
		grid-template-columns: 212px 1fr;
		height: 100%;
	}

	&__aside {
		padding: calc($unit * 9.5) calc($unit * 4);

		@media (--screen-to-med) {
			padding-top: calc($unit * 20);
			padding-bottom: calc($unit * 4);

			& > .nav--secondary {
				display: none;
			}
		}

		@media (--screen-from-med) {
			background-color: #f8fafc;
		}
	}

	&__main {
		padding: calc($unit * 4);
		min-width: 0;

		@media (--screen-to-med) {
			padding-bottom: 90px;
		}

		@media (--screen-from-med) {
			padding: 74px calc($unit * 12);
		}
	}

	&__footer {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;

		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		padding: calc($unit * 3) calc($unit * 4);
		gap: calc($unit * 4);

		border-top: 1px solid $grey-10;
		background-color: $white;

		@media (--screen-from-xlrg) {
			left: 492px;
			padding: calc($unit * 3) calc($unit * 12);
		}
	}

	&__mfa-code-card {
		display: flex;
		align-items: center;
		gap: calc($unit * 7.5);
		padding: calc($unit * 6);

		border: 1px solid $grey-10;
		border-radius: calc($global-radial * 1.5);

		@media (--screen-to-lrg) {
			flex-direction: column;
		}

		&__qr {
			flex-shrink: 0;
			width: 150px;

			@media (--screen-from-lrg) {
				width: 100px;
			}
		}

		&__separator {
			position: relative;

			display: block;
			height: 1px;
			width: 100%;

			background-color: $grey-10;

			&:after {
				content: 'Or';

				position: absolute;
				top: 50%;
				left: 50%;

				height: calc($unit * 7);
				width: calc($unit * 7);

				font-size: calc($type-nano * 1px);
				font-weight: $type-weight-regular;
				line-height: calc($unit * 7);
				text-align: center;

				background-color: $white;
				transform: translate(-50%, -50%);
			}

			@media (--screen-from-lrg) {
				height: 100px;
				width: 1px;
			}
		}

		&__input {
			display: block;
			flex-grow: 1;
			height: calc($unit * 9);
			width: 100%;
			padding: 0 calc($unit * 3);

			font-size: calc($type-plus-small * 1px);
			line-height: calc($unit * 5);
			font-weight: $type-weight-regular;
			color: $grey-50;

			border: 0;
			border-radius: $global-radial;
			background-color: $grey-10;
		}
	}

	&__sandbox-card {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: calc($unit * 10);
		padding: calc($unit * 4);

		text-align: center;

		border: 1px dashed $grey-10;
		border-radius: $global-radial;

		@media (--screen-from-med) {
			padding: calc($unit * 12);
		}
	}

	/* Mobile navigation */
	&__dropdown {
		@media (--screen-from-med) {
			display: none;
		}

		.popover {
			display: none;

			&.is-active {
				display: block;
			}
		}
	}

	/* Cobranding */
	&--cobranding {
		@media (--screen-from-med) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: calc($unit * 5);
		}

		@media (--screen-from-xxlrg) {
			grid-template-columns: 430px 430px;
			gap: calc($unit * 5);
		}

		&__card {
			@media (--screen-to-med) {
				padding: calc($unit * 4);
				padding-bottom: 0;
				border: 1px solid $grey-10;
				border-radius: calc($global-radial * 2);

				&:first-child {
					margin-bottom: calc($unit * 4);
				}
			}
		}
	}
}
