/*------------------------------------*\
    # utils.height
\*------------------------------------*/

.h {
	&--32 {
		height: 32px;
	}
}

.fullheight {
	height: 100%;
}
