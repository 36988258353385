/*------------------------------------*\
    # modules.input
\*------------------------------------*/

.input {
	/**
     * Input base
     */
	display: inline-block;
	width: 100%;

	vertical-align: middle;
	cursor: text;
	touch-action: manipulation;
	appearance: none;
	transition: border-color 100ms;
	-webkit-appearance: none;
	border-radius: $global-radial;

	/* Fixing input cursor on Firefox tab switching */
	caret-color: black;

	/**
     * Input base project styling
     */
	font-family: $ff-sans;
	border: 1px solid $grey-20;
	background-color: white;

	&::placeholder {
		color: $color-text-light;
	}

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:hover {
		border-color: color($grey-20 blackness(+50%));
	}

	&:disabled,
	&--disabled {
		color: $grey-50;
		border-color: $grey-20;
		background-color: $grey-10;
		cursor: default;

		&:hover {
			color: $grey-50;
			border-color: $grey-20;
			background-color: $grey-10;
			cursor: default;
		}
	}

	&:focus-visible {
		outline: 2px solid $color-primary;
		border-color: $color-primary;
	}

	&:focus {
		outline: 0;
		border-color: $color-primary;
	}

	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 $black;
	}

	&:-webkit-autofill {
		border-color: 1px solid $grey-10;
		-webkit-text-fill-color: black;
		-webkit-box-shadow: 0 0 0px 1000px $white inset;
		transition: background-color 200s ease-in-out;
	}

	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		border-color: color($grey-10 blackness(+50%));
	}

	@supports (-webkit-overflow-scrolling: touch) {
		@mixin type-scale $type-base, 1;
	}

	&.is-invalid,
	&:invalid {
		outline: 0;
		border-color: $error;

		&:focus {
			&:hover {
				border-color: $error;
			}
		}
	}

	&.input[type='number'] {
		-moz-appearance: textfield;
	}

	/* Input sizes */
	&--sml {
		height: calc($unit * 9);
		line-height: calc($unit * 9);
		padding: 0 calc($unit * 3);
	}

	&--med {
		height: calc($unit * 11);
		line-height: calc($unit * 11);
		padding: 0 calc($unit * 3);
	}

	/* Textual Elements */
	&--textarea {
		width: 100%;
		max-width: 100%;
		min-height: 108px;
		padding: calc($unit * 3);
		vertical-align: top;

		line-height: calc($unit * 5);

		&--vertical {
			resize: vertical;
		}

		&--one-row {
			height: calc($unit * 7);
		}
	}

	&--search {
		padding-right: calc($unit * 10);
		background-image: svg-load('../images/search.svg');
		background-position: right calc($unit * 3) center;
		background-size: calc($unit * 4);
		background-repeat: no-repeat;
	}

	&--password {
		position: relative;

		flex-grow: 1;

		border: 1px solid $grey-20;
		border-radius: $global-radial;
		background-color: $white;
		transition: border-color 200ms;

		&.focus {
			outline: 0;
			border-color: $color-primary;

			&:hover {
				border-color: $color-primary;
			}
		}

		&:hover {
			border-color: color($grey-20 blackness(+50%));
		}

		input {
			border: 0;
			width: calc(100% - 33px);
			height: calc($unit * 11);
		}

		& > .visibility-icon {
			position: absolute;
			top: 50%;
			right: calc($unit * 3);
			transform: translateY(-50%);

			display: flex;
			align-items: center;
			justify-content: center;

			cursor: pointer;
		}
	}

	&--currency {
		padding-left: calc($unit * 7);

		border: 1px solid $grey-20;
		border-radius: $global-radial;
		background-image: svg-load('../images/transactions.svg');
		background-position: left calc($unit * 1.5) center;
		background-repeat: no-repeat;
		background-size: calc($unit * 4);

		&.input--disabled {
			padding-left: calc($unit * 5);
			background-image: svg-load('../images/transactions-light.svg');
		}
	}

	/* Selection Elements */
	&--select {
		padding-right: calc($unit * 9);

		background-image: url(../images/chevron-down.svg);
		background-repeat: no-repeat;
		background-size: calc($unit * 4);
		background-position: right calc($unit * 3) center;

		cursor: pointer;

		&:hover {
			border-color: color($grey-10 blackness(+50%));
		}

		option {
			line-height: calc($unit * 6);
		}
	}

	&--date {
		padding-right: calc($unit * 10);
		vertical-align: middle;
		background-repeat: no-repeat;
		background-size: calc($unit * 4);
		background-position: right calc($unit * 3) center;
		background-image: svg-load('../images/calendar.svg');
	}

	&--multiselect {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: $unit;
		padding: calc($unit * 1.5);
		min-height: calc($unit * 11);

		/* Placeholder */
		[data-placeholder='true'] {
			display: none;
		}

		/* Tag styling */
		[data-tag='true'] {
			display: inline-flex;
			align-items: center;
			gap: calc($unit * 1.5);
			padding: calc($unit * 2) calc($unit * 2.5);
			padding-right: calc($unit * 3);

			font-size: calc($type-xsmall * 1px);
			line-height: calc($unit * 4);
			color: $color-text;

			border-radius: $unit;
			background-color: $grey-10;

			/* Remove button */
			& > [data-tag-handle='true'] {
				width: calc($unit * 3);
				height: calc($unit * 3);

				background-size: calc($unit * 3);
				background-image: svg-load('../images/close.svg');

				cursor: pointer;
			}
		}

		& > input {
			border: 0;
			outline: 0;
		}

		&--filter-dropdown {
			position: absolute;
			top: calc($unit * 9);

			display: flex;
			flex-direction: column;
			gap: $unit;
			overflow-y: auto;
			max-height: 250px;
			padding: calc($unit * 2);

			border: 1px solid $grey-10;
			border-radius: $global-radial;
			background-color: white;

			&__result {
				position: absolute;
				left: calc($unit * 2);
				top: $unit;

				font-size: calc($type-xsmall * 1px);
				font-weight: $type-weight-medium;
			}
		}
	}

	/* Radio and Checkbox */
	&--check,
	&--radio {
		opacity: 0;
		position: absolute;
		width: 0;

		& + label {
			position: relative;

			display: inline-flex;
			align-items: flex-start;
			gap: calc($unit * 2);

			@mixin type-scale $type-plus-small, 1;
			font-weight: $type-weight-regular;
			color: $color-text;

			cursor: pointer;

			&:before {
				content: '';

				display: block;
				flex-shrink: 0;
				width: calc($unit * 4);
				height: calc($unit * 4);
				margin-top: 2px;

				border: 1px solid $grey-20;
				border-radius: 2px;
				background-color: $white;
			}
		}

		&:disabled {
			& + label {
				opacity: 0.4;
				cursor: default;
			}
		}

		&[readonly] {
			& + label {
				cursor: default;
			}
		}

		&:focus-visible {
			& + label {
				border-radius: calc($global-radial / 2);
				outline: 2px solid $color-primary;
				outline-offset: 2px;
			}
		}

		&--no-label {
			& + label {
				width: calc($unit * 4);
				height: calc($unit * 4.5);
				overflow: hidden;
				color: transparent;
			}
		}
	}

	&--radio {
		& + label {
			&:before {
				border-radius: $global-rounded;
			}
		}

		&:checked {
			& + label {
				&:before {
					border: 4px solid $color-primary;
				}
			}
		}

		&:focus {
			& + label {
				&:before {
					box-shadow: $shadow-sml;
				}
			}
		}
	}

	&--check {
		&:checked {
			& + label {
				&:before {
					flex-shrink: 0;

					border-color: $color-primary;
					background-color: $color-primary;
					background-image: svg-load('../images/checkbox-checkmark.svg', stroke: $white);
					background-size: calc($unit * 2);
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}

		/* Use when checkbox is enabling whole form */
		&--enable-form {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
			gap: calc($unit * 4) calc($unit * 9.5);
			padding: calc($unit * 4);

			border-radius: $global-radial;
			background-color: $color-navigation;
		}
	}

	&--toggle {
		opacity: 0;
		position: absolute;
		width: 0;

		&:not(:disabled) {
			& + label {
				cursor: pointer;
			}
		}

		& + label {
			position: relative;
			display: block;
			width: calc($unit * 9);
			height: calc($unit * 5);

			color: transparent;

			border: 1px solid $color-primary;
			border-radius: $global-rounded;

			&:before {
				content: '';

				position: absolute;
				left: 3px;
				top: 2px;

				display: block;
				width: calc($unit * 3.5);
				height: calc($unit * 3.5);

				border-radius: $global-rounded;
				background-color: $color-primary;
				transition: all 250ms ease-in-out;
			}

			&:after {
				content: '';

				opacity: 0;

				position: absolute;
				left: 3px;
				top: 2px;

				display: block;
				width: calc($unit * 3.5);
				height: calc($unit * 3.5);

				mask-image: svg-load('../images/check.svg');
				mask-size: calc($unit * 3.5);
				mask-repeat: no-repeat;
				mask-position: center;

				transition: all 250ms ease-in-out;
			}
		}

		&:checked {
			& + label {
				background-color: $color-primary;

				&:before {
					left: 18px;
					background-color: $white;
				}

				&:after {
					opacity: 1;
					left: 18px;
					background-color: $color-primary;
				}
			}
		}

		&:disabled {
			& + label {
				opacity: 0.5;
			}
		}

		&--no-label {
			& + label {
				color: transparent;
			}
		}
	}

	/* Specialized Elements */
	&--stepper {
		display: flex;
		flex-wrap: nowrap;
		height: calc($unit * 9);
		overflow: hidden;
		border: 1px solid $color-navigation;
		border-radius: $global-radial;

		&__input {
			width: 100%;
			background-color: $white;
			border: 0;
			text-align: center;

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		&__button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc($unit * 9);
			flex-shrink: 0;

			border: 0;
			background-color: $color-navigation;
			cursor: pointer;

			transition: all 250ms ease-in-out;

			&:hover {
				background-color: color($color-navigation blackness(+5%));
			}

			&:disabled {
				cursor: default;

				&:hover {
					background-color: $color-navigation;
				}
			}

			.icon {
				pointer-events: none;
			}
		}
	}

	/* Customs */

	&--prefix {
		display: grid;
		grid-template-columns: auto 1fr;
		gap: calc($unit * 3);
		padding: 0 calc($unit * 2);

		font-size: calc($type-plus-small * 1px);
		line-height: calc($unit * 10.5);

		border: 1px solid $grey-20;
		border-radius: $global-radial;

		transition: border-color 100ms;

		&:has(input:focus) {
			border-color: $color-primary;
		}

		&:hover {
			border-color: color($grey-20 blackness(+50%));
		}

		&__main {
			width: 100%;
			border: 0;
			outline: 0;
			padding: 0;
			background-color: transparent;
		}
	}

	&--security {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		gap: $unit;

		& > .input {
			height: calc($unit * 15);
			flex-grow: 1;
			@mixin type-scale $type-xlarge, 2;
			text-align: center;

			&:focus {
				&::placeholder {
					color: transparent;
				}
			}
		}
	}

	&--box {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;
		padding: calc($unit * 2.5);
		gap: calc($unit * 2.5);

		border-radius: $global-radial;
		background-color: $color-navigation;
		outline: 0;
		border: 0;

		transition: all 250ms ease-in-out;

		&:hover {
			background-color: color($color-navigation blackness(+5%));
		}

		&.is-active {
			width: auto;
			justify-content: flex-start;

			@media (--screen-from-sml) {
				min-width: 210px;
			}
		}

		&.is-selected {
			justify-content: flex-start;
			padding: calc($unit * 1.5) calc($unit * 2.5);
			min-width: 210px;
		}

		&__input {
			padding: 0 calc($unit * 3);
			height: calc($unit * 8);
			line-height: calc($unit * 8);

			@media (--screen-to-sml) {
				position: absolute;
				top: calc(100% + 12px);
				left: calc($unit * 2);
				width: 200px;
				box-shadow: 0 0 0 calc($unit * 2) $color-navigation;
			}
		}
	}

	&--total {
		font-size: calc($type-xlarge * 1px);
		line-height: calc($unit * 11);
		font-weight: $type-weight-medium;
		border: 1px solid $grey-70;

		&.success {
			color: $success;
		}

		&.error {
			color: $error;
		}
	}
}

/* Custom */
select::-ms-expand {
	display: none;
}

@supports (-webkit-touch-callout: none) {
	input {
		font-size: 16px !important;
		line-height: normal !important;

		&::placeholder {
			font-size: 16px !important;
		}
	}
}
