/*------------------------------------*\
    # components.upload
\*------------------------------------*/

.upload {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: calc($unit * 3);
	padding: calc($unit * 6);
	width: 100%;
	height: 170px;

	border: 1px dashed $grey-10;
	border-radius: $global-radial;

	&__icon {
		width: 60px;
		height: 60px;

		background-image: svg-load('../../img/upload.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&__text {
		text-align: center;
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: calc($unit * 3);
		margin-top: calc($unit * 4);

		&__item {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: calc($unit * 3);
			padding: calc($unit * 2.5) calc($unit * 4) calc($unit * 2.5) calc($unit * 3);

			background-color: #f8fafc;
			border-radius: $global-radial;

			&__thumbnail {
				width: calc($unit * 4);
				height: calc($unit * 4);
				background-size: calc($unit * 4);
				background-image: svg-load('../images/file.svg');
			}

			&__remove {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: calc($unit * 7);
				height: calc($unit * 7);
				padding: 0;
				margin-left: auto;

				border: 0;
				background-color: transparent;
				outline: none;
				cursor: pointer;
			}
		}
	}

	&--preview {
		padding: 0;

		& > img {
			max-width: 100%;
			max-height: 100%;
		}

		& > .delete-button {
			position: absolute;
			top: calc($unit * 2);
			right: calc($unit * 2);
		}
	}
}
