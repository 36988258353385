/*------------------------------------*\
    # plugins.rc-menu
\*------------------------------------*/

.rc-menu-submenu {
	z-index: 999999;

	/* Dropdown */
	&.rc-menu-submenu-popup {
		@media (--screen-to-sml) {
			position: fixed;
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;

			display: flex;
			align-items: flex-end;

			background-color: color($color-secondary a(0.9));
		}

		& > .rc-menu {
			overflow-y: auto;
			max-height: 440px;
			min-width: 240px;
			padding: calc($unit * 2) 0;

			border: 1px solid $grey-10;
			border-radius: calc($global-radial * 2) calc($global-radial * 2) 0 0;
			background-color: $white;

			box-shadow: $shadow-big;

			@media (--screen-from-sml) {
				border-radius: calc($global-radial * 2);
			}

			@media (--screen-to-sml) {
				width: 100%;
			}

			/* Removing overflow for tooltip */
			&:has(.inputfromto__holder--single) {
				overflow: visible;

				& > .rc-menu-item {
					overflow: visible;
				}
			}

			/* Centering single datepicker */
			.inputfromto__holder--single {
				text-align: center;
			}

			&:has(.rc-menu-item > .rc-menu-footer) {
				padding-bottom: 0;
			}

			&.rc-menu-sub {
				& > .rc-menu-calendar-header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 calc($unit * 3);
					margin-bottom: calc($unit * 2);
				}

				& > .rc-menu-item {
					&:has(> button) {
						display: flex;
						padding: 0;
					}

					& > button {
						padding: calc($unit * 2);
						width: 100%;
						text-align: left;
						cursor: pointer;
						transition: all 250ms ease-in-out;

						&:hover {
							background-color: $color-navigation;
						}
					}
				}
			}

			& > .rc-menu-item {
				overflow-y: auto;
				padding: calc($unit * 2);
				margin: 0 calc($unit * 2);

				@mixin type-scale $type-plus-small, 1;
				color: $color-text;

				border-radius: $global-radial;
				transition: all 250ms ease-in-out;

				&:hover {
					&:not(.rc-menu-item-disabled) {
						cursor: pointer;
						background-color: $color-navigation;
					}
				}

				&:not(.rc-menu-item:has(.rc-menu-footer)) {
					flex-grow: 1;
				}

				& > .rc-menu-horizontal {
					background-color: transparent;
					border: 0;

					& > .rc-menu-submenu {
						border-bottom: 0;

						&.rc-menu-submenu-selected {
							background-color: transparent;
						}

						&.rc-menu-submenu-active {
							& > .rc-menu-submenu-title {
								background-color: $grey-10;
							}
						}

						& > .rc-menu-submenu-title {
							color: $color-text;
							border-radius: $global-radial;

							& > .rc-menu-submenu-arrow {
								width: calc($unit * 4);
								height: calc($unit * 4);
								background-color: $color-text;
								mask-size: calc($unit * 4);
								mask-image: svg-load('../images/chevron-right.svg');

								&:before {
									display: none;
								}
							}
						}
					}
				}

				& > button {
					padding: 0;
					background-color: transparent;
					border: 0;
				}

				/* Active state */
				&.rc-menu-item-active {
					background-color: $color-navigation;
				}

				/* Selected state */
				&.rc-menu-item-selected {
					background-color: var(--color-sidebar-final);
				}

				/* Searchbar at the top of the dropdown */
				& > .rc-menu-search {
					position: relative;
					margin: 0 calc($unit * 2);
					margin-bottom: calc($unit * 2);

					&:before {
						content: '';

						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);

						display: block;
						width: 20px;
						height: 20px;

						background-color: $color-text-light;
						mask-image: svg-load('../images/search.svg');
						mask-repeat: no-repeat;
						mask-size: calc($unit * 5);
						mask-position: left center;
					}

					& > .input {
						padding: 0;
						padding-left: calc($unit * 7);
						height: calc($unit * 13);
						line-height: calc($unit * 13);

						border: 0;
						border-radius: 0;
						border-bottom: 1px solid $grey-10;

						&:focus {
							border-color: $color-primary;
						}
					}
				}

				/* List of options */
				& > .rc-menu-list {
					& > .rc-menu-list-item {
						padding: calc($unit * 2);
						border-radius: $global-radial;
						transition: all 250ms ease-in-out;

						&:hover {
							background-color: var(--color-sidebar-final);
						}
					}
				}

				/* Footer */
				& > .rc-menu-footer {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: calc($unit * 4);

					& > .rc-menu-item {
						background-color: transparent;
						padding: 0;
					}
				}

				/* Footer's wrapper */
				&:has(.rc-menu-footer) {
					position: sticky;
					bottom: 0;

					flex-shrink: 0;
					padding: calc($unit * 3) calc($unit * 4);
					margin: 0;

					border-top: 1px solid $grey-10;
					border-radius: 0;
					background-color: white;
				}
			}

			& > .rc-menu-submenu {
				&.rc-menu-submenu-disabled {
					opacity: 0.3;
					pointer-events: none;
					cursor: default;

					& > .rc-menu-submenu-title {
						&:hover {
							background-color: transparent;
						}
					}
				}

				& > .rc-menu-submenu-title {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: calc($unit * 2);
					margin: 0 calc($unit * 2);

					@mixin type-scale $type-plus-small, 1;
					color: $color-text;

					border-radius: $global-radial;

					cursor: pointer;
					transition: all 250ms ease-in-out;

					&:hover {
						background-color: $grey-10;
					}

					&.rc-menu-item-selected {
						background-color: var(--color-sidebar-final);
					}

					& > .rc-menu-submenu-arrow {
						right: calc($unit * 3);

						width: calc($unit * 4);
						height: calc($unit * 4);

						mask-size: calc($unit * 4);
						mask-image: svg-load('../images/chevron-right.svg');
						background-color: $color-text;

						&:before {
							display: none;
						}
					}
				}

				&.rc-menu-submenu-active {
					background-color: transparent;

					& > .rc-menu-submenu-title {
						background-color: transparent;

						&:hover {
							background-color: $color-navigation;
						}
					}
				}

				&.rc-menu-submenu-selected {
					background-color: transparent;
				}

				/* Filter value enter */
				.rc-menu-submenu {
					padding-left: 28px;

					& > .rc-menu {
						padding: calc($unit * 4);

						&:has(.datepicker) {
							padding: 0;
						}

						& > .rc-menu-item {
							display: flex;
							align-items: center;
							gap: calc($unit * 4);
							margin: 0;
							padding: 0;
							border-radius: 0;

							&:hover,
							&.rc-menu-item-selected {
								background-color: white;
								cursor: default;
							}

							.input {
								min-width: 200px;
							}

							& > .datepicker {
								padding-bottom: 104px;
							}
						}
					}
				}
			}
		}
	}

	/* Main Filter Dropdown */
	&.rc-menu-filter {
		& > .rc-menu {
			& > .rc-menu-submenu {
				&.rc-menu-submenu-open {
					& > .rc-menu-submenu-title {
						background-color: $color-navigation;
					}
				}
			}
		}
	}

	/* Main Filter Submenu */
	&.rc-menu-filter-submenu {
		& > .rc-menu {
			& > .rc-menu-item {
				&.rc-menu-item-active {
					background-color: white;
				}
			}
		}
	}

	/* Custom Dropdown styling */
	&.rc-menu-filter-dropdown {
		& > .rc-menu {
			& > .rc-menu-item {
				padding: 0;
				margin: 0;

				& > .rc-menu {
					& > .rc-menu-submenu {
						width: 100%;

						& > .rc-menu-submenu-title {
							padding: calc($unit * 2);
							margin: 0 calc($unit * 2);
						}
					}
				}
			}
		}
	}

	/* Columns Dropdown */
	&.rc-menu-columns-dropdown {
		& > .rc-menu {
			&.rc-menu-vertical {
				padding: 0;

				& > .rc-menu-item {
					&:not(.rc-menu-item:has(.rc-menu-footer)) {
						padding: 0;
					}

					&.rc-menu-item-active {
						background-color: white;
					}

					& > .rc-menu-list {
						& > .rc-menu-list-item {
							background-position: right calc($unit * 2) center;
							background-size: calc($unit * 4);
							background-image: svg-load('../images/move.svg');
							background-repeat: no-repeat;
							cursor: pointer;

							&:hover {
								background-color: $color-navigation;
							}
						}
					}
				}
			}
		}
	}

	/* Single Datepicker */
	&.rc-menu-submenu-single-date {
		& > .rc-menu {
			min-width: auto !important;
		}
	}

	/* Save View */
	&.rc-menu-save-view {
		& > .rc-menu {
			padding: 0;

			& > .rc-menu-item {
				padding: calc($unit * 6);
				margin: 0;

				&.rc-menu-item-disabled {
					background-color: white;
				}
			}
		}

		.rc-menu-save-view__footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: calc($unit * 4) calc($unit * 6);
			margin: calc(-$unit * 6);
			margin-top: 0;

			background-color: $color-navigation;
		}

		.type--p2 {
			white-space: normal;
		}
	}

	/* List of predefined dates */
	&.rc-menu-datepicker-tooltip {
		& > ul {
			& > .rc-menu-item {
				&.rc-menu-item-active,
				&:hover {
					background-color: $grey-10 !important;
				}
			}
		}
	}
}

/* Select input styling */
.rc-menu-select {
	& > .rc-menu {
		border: 0;

		&:focus-visible {
			& > .rc-menu-submenu {
				outline: 1px solid $color-primary;
			}
		}

		& > .rc-menu-submenu {
			overflow: hidden;
			padding: 0 calc($unit * 3);

			border: 1px solid $grey-20;
			border-radius: $global-radial;

			transition: all 100ms ease-in-out;
			cursor: pointer;

			&.rc-menu-submenu-active {
				border-color: $grey-50;

				& > .rc-menu-submenu-title {
					background-color: $white;
				}
			}

			&.rc-menu-submenu-selected {
				width: 100%;
				background-color: $white;
			}

			&.rc-menu-submenu-open {
				border-color: $color-primary;
			}

			& > .rc-menu-submenu-title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0;
				height: calc($unit * 11 - 2px);
				font-size: calc($type-plus-small * 1px);
				line-height: calc($unit * 11 - 2px);
				color: $color-text;

				/* Chevron icon on the right side */
				&:after {
					content: '';

					display: block;
					width: calc($unit * 4);
					height: calc($unit * 4);
					flex-shrink: 0;

					background-color: $color-text;
					mask-image: svg-load('../images/chevron-down.svg');
					mask-size: calc($unit * 4);
					mask-position: center;
				}
			}
		}
	}
}

/* Datepicker */
.rc-menu {
	&:has(.DayPickerInput) {
		border-bottom: 0;
		background-color: transparent;
	}

	& > .rc-menu-submenu {
		&.DayPickerInput {
			width: 100%;
			border-bottom: 0;

			& > .rc-menu-submenu-title {
				padding: 0;

				& > .input {
					height: calc($unit * 11);
					line-height: calc($unit * 11);
					padding: 0 calc($unit * 3);
				}
			}

			&.rc-menu-submenu-open {
				.input {
					outline: 0;
					border-color: $color-primary;
				}
			}
		}
	}
}

/* Custom rc-menu look */
