/*------------------------------------*\
    # components.tabs
\*------------------------------------*/

.tabs {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	gap: calc($unit * 4);
	overflow-x: auto;
	width: 100%;

	border-bottom: 1px solid $grey-10;

	&__link {
		position: relative;

		display: flex;
		align-items: center;
		gap: calc($unit * 2);
		padding: calc($unit * 3) calc($unit * 2);
		white-space: nowrap;

		font-weight: $type-weight-medium;
		color: $color-text-light;

		border: 0;
		background-color: transparent;
		cursor: pointer;

		&:hover {
			color: $color-primary;
		}

		&:focus-visible {
			outline-offset: -2px;
		}

		&.is-active {
			color: $color-primary;

			&:after {
				content: '';

				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;

				display: block;
				height: 2px;

				background-color: $color-primary;
			}
		}

		&__counter {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-width: calc($unit * 3.5);
			height: calc($unit * 3.5);
			padding: 0 $unit;

			font-size: calc($type-nano * 1px);
			line-height: calc($unit * 3.5);
			color: $white;

			border-radius: $global-rounded;
			background-color: $color-primary;
		}
	}

	&--vertical {
		display: flex;
		flex-direction: column;
		gap: calc($unit * 3);

		&__item {
			position: relative;

			&__link {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: calc($unit * 3);
				width: 100%;
				padding: calc($unit * 2) calc($unit * 4);

				font-size: calc($type-plus-small * 1px);
				line-height: calc($unit * 5);
				font-weight: $type-weight-medium;
				white-space: nowrap;

				border: 0;
				border-radius: $global-rounded;
				background-color: transparent;
				transition: all 250ms ease-in-out;

				cursor: pointer;

				&.is-active {
					background-color: color($color-primary lightness(+40%));
					color: $color-primary;
				}

				&:hover:not(.is-active) {
					background-color: color($color-navigation lightness(-3%));
				}
			}
		}

		&__wrapper {
			padding: calc($unit * 2);
			border-radius: calc($global-radial * 3);
			background-color: $color-navigation;

			&:has(.tabs--vertical__search) {
				padding-top: 0;
			}

			&__subcategory {
				position: absolute;
				top: 0;
				left: 100%;
				display: flex;

				&:before {
					content: '';

					display: block;
					width: calc($unit * 4);
					height: calc($unit * 4);
					background-color: transparent;
				}
			}
		}

		&__search {
			width: 100%;
			height: calc($unit * 13);
			padding-left: calc($unit * 10);
			margin-bottom: calc($unit * 3);

			line-height: calc($unit * 13);

			border: 0;
			border-bottom: 1px solid $grey-10;
			background-color: transparent;
			background-image: svg-load('../images/search.svg');
			background-size: calc($unit * 5);
			background-position: left calc($unit * 3) center;
			background-repeat: no-repeat;
			outline: none;

			&:hover {
				border-color: $grey-20;
			}

			&:focus {
				border-color: $color-primary;
			}
		}
	}

	& > .nav--secondary__item {
		& > .nav--secondary__link {
			height: calc($unit * 11);
			white-space: nowrap;
			border-radius: 0;
			border-bottom: 2px solid transparent;

			&:hover {
				color: $color-primary;
				background-color: transparent;
			}

			&.is-active {
				background-color: transparent;
				border-color: $color-primary;
			}
		}
	}
}
