/*------------------------------------*\
    # components.emptystate
\*------------------------------------*/

.emptystate {
	margin-top: calc($unit * 2);
	margin-bottom: calc($unit * 6);
    padding: calc($unit * 8) calc($unit * 3);
    background-color: $grey-0;
    border: 1px solid $grey-10;
    border-radius: $global-radial;

    @media (--screen-from-lrg) {
        padding-left: calc($unit * 8);
    }

    &__title {
        margin-bottom: $unit;
        @mixin type-scale $type-large, 1.25;
        font-weight: $type-weight-bold;

        @media (--screen-from-lrg) {
            @mixin type-scale $type-xxlarge, 2;
        }
    }

    &__message {
        margin-bottom: calc($unit / 2);
        color: $color-text-light;

        @media (--screen-to-med) {
            font-weight: $type-weight-regular;
        }
    }
}