/*------------------------------------*\
    # utils.align
\*------------------------------------*/

.align {
	&--h {
		&--center {
			margin-left: auto;
			margin-right: auto;
		}

		&--left {
			margin-right: auto;
		}

		&--right {
			margin-left: auto;
		}
	}
}
