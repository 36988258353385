/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: $ff-sans;
	font-weight: $type-weight-medium;
	letter-spacing: -0.5px;
}

h1 {
	font-size: calc($type-huge * 1px);
	line-height: calc($unit * 15);
}

h2 {
	font-size: calc($type-big * 1px);
	line-height: calc($unit * 13.5);
}

h3 {
	font-size: calc($type-xxlarge * 1px);
	line-height: calc($unit * 10);
}

h4 {
	font-size: calc($type-xlarge * 1px);
	line-height: calc($unit * 7.5);
}

h5 {
	font-size: calc($type-base-alt * 1px);
	line-height: calc($unit * 6);
}

h6 {
	font-size: calc($type-base * 1px);
	line-height: calc($unit * 5);
}

/**
  * Paragraphs
  */
p {
	margin: 0;
	font-weight: $type-weight-regular;
	word-break: break-word;
}

/**
  * Lists (ol, ul, dd)
  */
ol,
ul,
dl {
	margin: 0;
	padding: 0;
	list-style: none;
}

/**
  * Anchors
  */
a {
	font-weight: $type-weight-medium;
	text-decoration: none;
	color: $color-primary;
}

a:hover {
	color: $color-primary;
}

a:focus {
	outline: 0;
	color: $color-primary;
}

a:focus-visible {
	border-radius: calc($global-radial / 2);
	outline: 2px solid $color-primary;
	outline-offset: 2px;
}
