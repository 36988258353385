/*------------------------------------*\
    # modules.layout
\*------------------------------------*/

.l {
	&--main {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 3;

		display: flex;
		flex-direction: column;
		overflow-y: auto;
		margin: 0 auto;
		margin-top: 76px;

		transition: all 250ms ease-in-out;

		@media (--screen-from-med) {
			left: $aside-width-collapsed;
		}

		@media (--screen-from-xlrg) {
			margin-top: 87px;
			left: $aside-width-sml;
		}

		@media (--screen-from-xxlrg) {
			left: $aside-width;
			overflow-x: visible;
		}

		&:has(.popover__wrapper) {
			& + .l--aside {
				&:after {
					content: '';

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 10;

					display: block;
					background-color: color($color-secondary a(0.9));
				}
			}
		}

		&:has(.settings),
		&:has(.leads) {
			margin-top: 0;
		}

		&:has(.settings) {
			overflow: hidden;
		}

		&.is-collapsed {
			@media (--screen-from-xlrg) {
				left: $aside-width-collapsed;

				.header {
					left: $aside-width-collapsed;
				}

				.settings__footer {
					left: 284px;
				}
			}

			.virtual-terminal__new-transaction__footer {
				left: $aside-width-collapsed;
			}
		}

		&.sidebar-is-expanded {
			@media (--screen-to-med) {
				z-index: 5;
			}

			@media (--screen-from-xlrg) {
				right: $sidebar-width;

				.header {
					right: $sidebar-width;
				}
			}
		}

		&.subnav-visible {
			margin-top: 0;
		}

		&.no-header {
			margin-top: 0;
		}
	}

	&--content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden auto;
		scrollbar-gutter: stable;
		padding: calc($unit * 5) calc($unit * 4);
		height: 100%;

		@media (--screen-from-med) {
			padding: calc($unit * 5) calc($unit * 6);
		}

		@media (--screen-from-xxlrg) {
			padding: calc($unit * 8) calc($unit * 12);
		}

		&:has(.l--content) {
			padding: 0;
			overflow: visible;
		}

		&.is-expanded {
			position: relative;
			padding-right: 571px;
		}

		&.subnav-visible {
			padding: 0;
			scrollbar-gutter: auto;

			@media (--screen-from-med) {
				display: grid;
				grid-template-columns: 220px 1fr;
			}
		}

		&:has(.settings) {
			padding: 0;
		}

		/* Customs */
		&--alt {
			overflow-y: auto;
			padding: calc($unit * 4);

			@media (--screen-from-med) {
				padding: calc($unit * 9.5) calc($unit * 12) calc($unit * 23.5);
			}
		}

		/* Use on pages where react-grid is main element */
		&--no-bottom-padding {
			padding-bottom: 0;
		}

		&--mfa {
			margin: 0 auto;
			padding-top: 56px;
			max-width: 514px;
			width: 100%;

			@media (--screen-from-lrg) {
				padding-left: 109px;
			}
		}

		&--eapp-equipment {
			padding-bottom: 0;
		}
	}

	&--aside {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 4;

		display: flex;
		flex-direction: column;
		width: 100%;

		color: $color-text;

		background-color: $color-navigation;

		transition: all 250ms ease-in-out;

		&:hover,
		&:has(.nav--help-center__wrapper) {
			z-index: 4;
		}

		&:not(.is-collapsed) {
			overflow: hidden auto;
		}

		@media (--screen-from-sml) {
			width: $aside-width;
		}

		&.is-revealed {
			@media (--screen-to-xlrg) {
				transform: translateX(0);
			}
		}

		&.is-collapsed {
			@media (--screen-from-med) {
				width: $aside-width-collapsed;
			}

			@media (--screen-to-med) {
				transform: translateX(-100%);
			}

			.sidebar {
				flex-grow: 0;
				overflow: visible;
				margin-bottom: calc($unit * 3);
			}

			.sidebar__account {
				& > .header__account {
					& > .header__account__profile {
						width: calc($unit * 7);
						height: auto;
						aspect-ratio: 1;
					}

					& > .header__account__name__wrapper {
						display: none;
					}
				}

				& > .sidebar__account__logout {
					display: none;
				}
			}

			.btn--sidebar-toggle {
				left: 51px;
			}

			.sidebar__nav__wrapper {
				height: calc(100vh - 118px);
				overflow-y: visible;
			}

			.sidebar__footer--collapsed {
				display: block;
			}

			.nav {
				padding: $unit calc($unit * 4.5);
			}
		}

		&--sub {
			padding: calc($unit * 9.5) calc($unit * 4);
			background-color: #f8fafc;

			@media (--screen-to-med) {
				padding: 0 calc($unit * 4);
				padding-top: calc($unit * 8);
				background-color: transparent;
			}
		}
	}

	&--success-submit {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: calc($unit * 4);
		height: 100%;

		& > .icon--regular--check {
			width: calc($unit * 20);
			mask-size: calc($unit * 20);
			background-size: calc($unit * 20);
		}
	}
}
