.react-grid-Cell {
  background-color: #ffffff;
  padding-left: 8px;
  padding-right: 8px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #dddddd;
}

.rdg-selected {
  border: 2px solid #66afe9;
}

.rdg-selected-range {
  border: 1px solid #66afe9;
  background-color: #66afe930;
}

.moving-element {
  will-change: transform;
}
.react-grid-Cell--frozen {
  /* Should have a higher value than 1 to show in front of cell masks */
  z-index: 2;
}
.rdg-last--frozen {
  border-right: 1px solid #dddddd;
  box-shadow: 2px 0 5px -2px rgba(136, 136, 136, .3) !important;
}

/* cell which have tooltips need to have a higher z-index on hover so that the tooltip appears above the other cells*/
.react-grid-Cell.has-tooltip:hover {
  z-index: 1;
}

.react-grid-Cell.react-grid-Cell--frozen.has-tooltip:hover  {
  z-index: 3
}

.react-contextmenu--visible {
  z-index: 1000;
}

.react-grid-Cell:not(.editing) .react-grid-Cell__value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: inherit;
}

.react-grid-Cell.readonly {
  background-color : black;
}


.react-grid-Cell:hover {
  background: #eee;
}

.react-grid-cell .form-control-feedback {
  color: #a94442;
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 1000000;
  display: block;
  width: 34px;
  height: 34px;
}

.react-grid-Row.row-selected .react-grid-Cell{
 background-color: #DBECFA;
}

.react-grid-Cell.editing {
  padding: 0;
  overflow: visible !important;
}

.react-grid-Cell.editing .has-error input {
  border: 2px red solid !important;
  border-radius: 2px !important;
}

.react-grid-Cell__value ul {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}

.react-grid-Cell__value .btn-sm {
  padding: 0;
}

.cell-tooltip .cell-tooltip-text {
    white-space: normal;
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    top: 50%;
    bottom: initial;
    left: 50%;
    margin-top: 15px;
    margin-left: -75px
}

.cell-tooltip:hover .cell-tooltip-text {
    visibility: visible;
    opacity: 0.8;
}

.cell-tooltip .cell-tooltip-text::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.react-grid-Canvas.opaque .react-grid-Cell.cell-tooltip:hover .cell-tooltip-text {
    visibility: hidden;
}

.rdg-cell-expand {
    float: right;
    display: table;
    height: 100%;
}

.rdg-cell-expand > span {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.rdg-child-row-action-cross:before, rdg-child-row-action-cross:after, .rdg-child-row-action-cross-last:before, rdg-child-row-action-cross-last:after {
  content: "";
  position: absolute;
  background: grey;
  height: 50%;
}

.rdg-child-row-action-cross:before {
  left: 21px;
  width: 1px;
  height: 100%;
}

.rdg-child-row-action-cross-last:before {
  left: 21px;
  width: 1px;
}

.rdg-child-row-action-cross:after, .rdg-child-row-action-cross-last:after {
  top: 50%;
  left:20px;
  height: 1px;
  width: 15px;
  content: "";
  position: absolute;
  background: grey;
}

.rdg-child-row-action-cross:hover {
    background: red;
}
.rdg-child-row-btn {
   position:absolute;
   cursor:pointer;
   border:1px solid grey;
   border-radius:14px;
   z-index: 3;
   background: white;
}

.rdg-child-row-btn div {
  font-size: 12px;
  text-align:center;
  line-height:19px;
  color: grey;
  height:20px;
  width:20px;
  position:absolute;
  top:60%;
  left:53%;
  margin-top:-10px;
  margin-left:-10px;

}

.rdg-empty-child-row:hover .glyphicon-plus-sign {
  color: green;
}

.rdg-empty-child-row:hover a {
  color: green;
}

.rdg-child-row-btn .glyphicon-remove-sign:hover {
  color: red;
}

.last-column .cell-tooltip-text{
  right: 100%;
  left: 0% !important;
}

.rdg-cell-action {
  float: right;
  height: 100%;
}

.rdg-cell-action-last {
  margin-right: -8px;
}

.rdg-cell-action-button {
  width: 35px;
  height: 100%;
  text-align: center;
  position: relative;
  display: table;
  color: #4a9de2;
}

.rdg-cell-action-button > span {
  display: table-cell;
  vertical-align: middle;
}

.rdg-cell-action-button:hover, .rdg-cell-action-button-toggled {
  color: #447bbb;
}

.rdg-cell-action-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px 0 #ccc;
}

.rdg-cell-action-menu > span {
  display: block;
  padding: 3px 10px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.rdg-cell-action-menu > span:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
