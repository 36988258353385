/*------------------------------------*\
    # plugins.react-grid
\*------------------------------------*/

.react-grid {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-bottom: calc($unit * 4);
	height: 100%;

	& > .react-grid-Container {
		height: 100%;

		& > .react-grid-Main {
			height: 100%;
			outline: 0;

			& > .react-grid-Grid {
				height: 100%;
				border: 0;

				/* Header */
				& > .react-grid-Header {
					z-index: 1;

					height: 40px !important;

					border-radius: $global-radial;
					background-color: $color-navigation;
					box-shadow: none;

					& > .react-grid-HeaderRow {
						overflow-y: hidden;
						min-height: 40px !important;

						*:not(textarea)::-webkit-scrollbar {
							background-color: $color-navigation;
						}

						.react-grid-HeaderCell {
							display: flex !important;
							align-items: center;
							padding: calc($unit * 3) calc($unit * 3.5);

							border-right: 1px solid transparent;
							border-bottom: 0;
							background: $color-navigation;

							cursor: pointer;

							&:hover {
								border-right: 1px solid $color-primary;
								background-color: #dfedf9;
							}

							&:has(.input--check) {
								z-index: 999999;
							}

							/* Header cell border radius */
							&:first-child {
								border-radius: calc($global-radial / 2) 0 0 calc($global-radial / 2);

								[data-tooltip='Column Sorting'] {
									&[data-tooltip]:before {
										top: 50%;
										right: auto;
										bottom: auto;
										left: calc(100% + 11px);
										transform: translate(0%, -50%);
									}

									&[data-tooltip]:after {
										top: 50%;
										bottom: auto;
										left: calc(100% + 6px);

										border-top: 5px solid transparent;
										border-right: 5px solid $color-text;
										border-bottom: 5px solid transparent;
										border-left: 0;

										transform: translate(0, -50%);
									}
								}
							}

							&:last-child {
								border-radius: 0 calc($global-radial / 2) calc($global-radial / 2) 0;
							}

							&--frozen {
								display: inline-flex !important;
								z-index: 99999;
								border-radius: calc($global-radial / 2) 0 0 calc($global-radial / 2) !important;
								box-shadow: none;

								&:last-child {
									border-right: 1px solid $color-text;
								}
							}

							/* The second wrapper width so flex can be applied to the content */
							& > .react-grid-HeaderCell-sortable {
								width: 100%;
							}

							/* Header cell content wrapper */
							.react-grid-HeaderCell-content {
								display: flex;
								align-items: center;
								justify-content: space-between;
								flex-grow: 1;
								gap: $unit;
								width: 100%;

								&.align-right,
								&.right,
								&.type--right {
									justify-content: flex-end;
								}
							}

							/* Column name and sorting */
							.name,
							.widget-HeaderCell__value,
							.react-grid-HeaderCell-sortable {
								min-width: 0;
								overflow: hidden;

								@mixin type-scale $type-nano, 0.75;
								font-weight: $type-weight-semibold;
								text-transform: uppercase;
								letter-spacing: 0.5px;
								text-overflow: ellipsis;
								color: $color-text-light;
							}

							/* Sorting icon */
							.sorting-icon-wrapper {
								z-index: 10;

								display: flex;
								flex-shrink: 0;
								align-items: center;
								justify-content: center;
								width: calc($unit * 5);
								height: calc($unit * 5);
								margin-right: 1px;

								border: 0;
								border-radius: $global-rounded;
								background-color: transparent;
								outline: 0;

								&:hover {
									background-color: #c6d8e6;
								}

								.sorting-icon {
									width: calc($unit * 2);
									height: calc($unit * 2);
									mask-size: calc($unit * 2);
								}
							}

							/* Removing native sorting icon because of custom icon with bigger control */
							.pull-right {
								display: none;
							}

							/* Tooltip */
							.icon--regular--info {
								z-index: 10;
							}

							.type--title {
								margin-right: auto;
							}
						}

						/* Filter row */
						&:not(:first-child) {
							top: 0 !important;

							.react-grid-HeaderCell {
								opacity: 0;
								height: 40px !important;
								padding: 0 calc($unit * 3.5);

								&:hover {
									opacity: 1;
								}

								&:has(.input:focus) {
									opacity: 1;
								}
								
								&:has(> .input:not(:placeholder-shown)) { 
									opacity: 1;
								}

								&:has(> button .input[placeholder]),
								&:has(> button .grid__multiselect) {
									opacity: 1;
								}

								.input {
									height: calc($unit * 7);
									padding-right: calc($unit * 5);
									font-size: calc($type-nano * 1px);
									border-radius: calc($global-radial / 2);
								}

								.clear-filter-button {
									position: absolute;
									top: 50%;
									right: calc($unit * 9);
									transform: translateY(-50%);
								}
							}
						}
					}
				}

				/* Body */
				.react-grid-Viewport {
					inset: 40px 0px 0px !important;

					& > .react-grid-Canvas {
						height: 100% !important;

						.grid-style-even,
						.grid-style-odd {
							&.is-expanded {
								.react-grid-Cell {
									border-top: 1px solid $grey-20;
									border-bottom: 1px solid $grey-20;
								}
							}

							& > a {
								color: $color-text;
								font-weight: $type-weight-regular;

								&:hover {
									color: $color-text;
								}
							}

							.grid-style--no-cursor {
								cursor: default;
							}
						}

						.react-grid-Row {
							display: flex;
							cursor: pointer;
							overflow: visible !important;

							&:hover {
								& > .react-grid-Cell {
									background-color: #f1f5f8;
								}
							}

							& > .react-grid-Cell {
								padding: 0 calc($unit * 3.5);
								border-right: 0;
								border-top: 1px solid transparent;
								border-color: transparent;

								.credit-card {
									display: flex;
									align-items: center;
									gap: calc($unit * 2.5);

									&--icon {
										width: calc($unit * 6);
									}
								}

								&--frozen {
									position: sticky !important;
									display: inline-flex !important;
									z-index: 9999;
									left: auto !important;

									&:last-child {
										border-right: 1px solid $color-text;
									}
								}

								& > .react-grid-Cell__value {
									&:has(.btn--link) {
										overflow: visible;
									}
								}
							}

							&--odd {
								& > .react-grid-Cell {
									background-color: #f8fafc;
								}
							}
						}
					}
				}

				/* Empty state */
				& > .react-grid-Empty {
					position: absolute;
					top: 40px;
					right: 0;
					bottom: 0;
					left: 0;

					display: flex;
					align-items: flex-start;
					justify-content: center;
					flex-direction: column;
					width: 100% !important;

					background: linear-gradient(180deg, #ffffff 33.44%, #f6f8fa 100%);

					.react-grid-Empty-img {
						flex-shrink: 0;
						width: 130px;
						margin-left: calc($unit * 10);

						aspect-ratio: 1;
						margin-bottom: calc($unit * 6);
						background-image: url('../../img/emptystate.png');
						background-size: 100%;
					}

					.react-grid-Empty-title {
						margin-bottom: calc($unit * 2);
						padding-left: calc($unit * 10);
						@mixin type-scale $type-large, 1.25;
						font-weight: $type-weight-medium;
					}

					.react-grid-Empty-text {
						padding-left: calc($unit * 10);
						color: $color-text-light;
					}
				}
			}
		}
	}

	/* Removing selected cell styling */
	.rdg-selected {
		border: 0;
	}

	&__tooltip {
		width: 200px;
		padding: calc($unit * 2) calc($unit * 3);
		white-space: normal;

		font-size: calc($type-xsmall * 1px);
		font-style: normal;
		text-align: center;
		font-weight: $type-weight-medium;
		line-height: calc($unit * 4);
		text-transform: none;
		color: $white;

		border-radius: calc($global-radial / 2);
		background-color: $color-secondary;
		box-shadow: $shadow-tooltip;
	}

	/* Footer */
	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: calc($unit * 4);
		padding: calc($unit * 4) 0;

		@media (--screen-from-med) {
			justify-content: flex-start;
			gap: calc($unit * 8);
		}

		&__details {
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			gap: calc($unit * 3);

			text-wrap: nowrap;

			@media (--screen-to-lrg) {
				position: absolute;
				bottom: calc(100% + calc($unit * 2.5));
				right: 0;
				z-index: 9999;

				display: none;
				flex-direction: column;
				padding: calc($unit * 3);

				border: 1px solid $grey-10;
				border-radius: $global-radial;
				background-color: $white;
				box-shadow: $shadow-big;
			}

			&.is-popover {
				display: flex;
			}

			&__item {
				display: flex;
				align-items: center;
				gap: $unit;

				@media (--screen-to-lrg) {
					gap: calc($unit * 4);
					width: 100%;
					justify-content: space-between;
				}
			}
		}
	}

	/* Actions */
	&__actions {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: calc($unit * 4);
	}

	/* Fixed element */
	&__fixed {
		box-shadow: -1px 0 0 0 $grey-20;
		right: 6px !important;

		.react-grid-Canvas {
			&::-webkit-scrollbar-thumb {
				background: transparent;
			}

			&::-webkit-scrollbar {
				width: 0;
			}
		}
	}
}

.rdg-cell-action {
	float: left;
}
