.react-grid-Header {
  box-shadow: 0px 0px 4px 0px #dddddd;
  background: #f9f9f9;
}
.react-grid-Header--resizing {
  cursor: ew-resize;
}
.react-grid-HeaderRow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.react-grid-HeaderCell {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #f9f9f9;
  padding: 8px;
  font-weight: bold;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.react-grid-HeaderCell__value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.react-grid-HeaderCell__resizeHandle:hover {
  cursor: ew-resize;
  background: #dddddd;
}
.react-grid-HeaderCell--frozen:last-of-type {
    box-shadow: 2px 0 5px -2px rgba(136, 136, 136, .3);
}
.react-grid-HeaderCell--resizing .react-grid-HeaderCell__resizeHandle {
  background: #dddddd;
}
.react-grid-HeaderCell__draggable {
    cursor: col-resize
}
.rdg-can-drop > .react-grid-HeaderCell {
  background: #ececec;
}

.react-grid-HeaderCell .Select {
    max-height: 30px;
    font-size: 12px;
    font-weight: normal;
}

.react-grid-HeaderCell .Select-control {
    max-height: 30px;
    border: 1px solid #cccccc;
    color: #555;
    border-radius: 3px;
}

.react-grid-HeaderCell .is-focused:not(.is-open) > .Select-control {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.react-grid-HeaderCell .Select-control .Select-placeholder {
    line-height: 20px;
    color: #999;
    padding: 4px;
}

.react-grid-HeaderCell .Select-control .Select-input {
    max-height: 28px;
    padding: 4px;
    margin-left: 0px
}

.react-grid-HeaderCell .Select-control .Select-input input {
    padding: 0px;
    height: 100%;
}

.react-grid-HeaderCell .Select-control .Select-arrow-zone .Select-arrow {
    border-color: gray transparent transparent;
    border-width: 4px 4px 2.5px;
}


.react-grid-HeaderCell .Select-control .Select-value {
    padding: 4px;
    line-height: 20px !important;
}

.react-grid-HeaderCell .Select--multi .Select-control .Select-value {
    padding: 0px;
    line-height: 16px !important;
    max-height: 20px;
}

.react-grid-HeaderCell .Select--multi .Select-control .Select-value .Select-value-icon {
    max-height: 20px;
}

.react-grid-HeaderCell .Select--multi .Select-control .Select-value .Select-value-label {
    max-height: 20px;
}

.react-grid-HeaderCell .Select-control .Select-value .Select-value-label {
    color: #555555 !important;
}

.react-grid-HeaderCell .Select-menu-outer {
  z-index: 2;
}

.react-grid-HeaderCell .Select-menu-outer .Select-option {
    padding: 4px;
    line-height: 20px;
}

.react-grid-HeaderCell .Select-menu-outer .Select-menu .Select-option.is-selected {
    color: #555555;
}

.react-grid-HeaderCell .Select-menu-outer .Select-menu .Select-option.is-focused {
    color: #555555;
}

