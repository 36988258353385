/*------------------------------------*\
    # modules.inputgroup
\*------------------------------------*/

.inputgroup {
	display: table;
	position: relative;
	border-collapse: separate;
	width: 100%;

	&.is-invalid {
		box-shadow: 0 0 0 2px #ffe8e6;
		border-radius: $global-radial;

		input,
		.fakeinput {
			border-color: $error;
		}

		& > .inputgroup--aside {
			& > .fakeinput {
				border-right: 1px solid $grey-10;
			}
		}

		& > .inputgroup--main {
			& > .input {
				border-left: 0;
			}
		}
	}

	&--main {
		display: table-cell;
		width: 100%;
		position: relative;

		&--bordered {
			.input {
				border-radius: $global-radial 0 0 $global-radial;
				border-right: 0;
			}

			&--reverse {
				.input {
					border-radius: 0 $global-radial $global-radial 0;
					border-left: 1px solid transparent;
					border-right: 1px solid $grey-10;

					&:hover {
						border-right: 1px solid $grey-30;
						border-left: 1px solid $grey-30;
					}

					&:focus {
						border-right: 1px solid $color-primary;
						border-left: 1px solid $color-primary;
					}

					&:disabled {
						border-right: 1px solid $grey-10;
						border-left: 1px solid $grey-10;
					}
				}
			}
		}

		&--double {
			.input {
				border-radius: 0;
			}
		}
	}

	&--aside {
		display: table-cell;
		width: 1%;
		vertical-align: middle;
		position: relative;

		&--bordered {
			input,
			button {
				border-radius: 0 $global-radial $global-radial 0;
			}

			&--reverse {
				& > input,
				& > button,
				& > .fakeinput {
					border-radius: $global-radial 0 0 $global-radial;
				}
			}

			&--right {
				border-radius: 0 $global-radial $global-radial 0;
			}
		}

		&--iconed {
			padding: 0 calc($unit * 2);
			background-color: $grey-0;
			text-align: center;
			border-right: 0;
		}

		&--label {
			padding-right: calc($unit * 2);
		}

		.btn--handler {
			border-radius: $global-radial 0 0 $global-radial;
		}

		&--double {
			.btn--handler {
				border-radius: 0 $global-radial $global-radial 0;
			}
		}
	}

	&__input {
		border: 1px solid transparent;
	}
}
