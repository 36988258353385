/*------------------------------------*\
    # print.fraudprint
\*------------------------------------*/

@page {
    margin: 17mm 9mm 17mm 9mm;
}

@media print {
    .print-hide {display:none;}
}

.fraudprint {
    &__header {
        margin: 0 0 calc($unit * 4);
    }

    &__footer {
        display: none;
        position: static;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        padding-top: calc($unit * 2);

        svg {
            width: 100px;
            float: right;
        }
    }

    &__table {
        width: 100%;
        margin: 0 0 calc($unit * 2);
        border-collapse: collapse;

        th {
            padding: calc($unit * 2) $unit;
            @mixin type-scale $type-nano, 0.5;
            text-align: left;
            border: 1px solid $grey-10;
            vertical-align: top;

            &:first-child {
                width: calc($unit * 10);
            }

            &:nth-child(2) {
                width: calc($unit * 20);
            }

            &:nth-child(3) {
                width: calc($unit * 15);
            }

            &:nth-child(4) {
                width: calc($unit * 15);
            }
        }

        tr {
            &:first-child {
                td {
                    padding-top: calc($unit * 2);
                }
            }
        }

        td {
            padding: calc($unit * 1) $unit;
            @mixin type-scale $type-nano, 0.5;
            text-align: left;
            border: 1px solid $grey-10;

            &:first-child, &:nth-child(3) {
                font-weight: $type-weight-medium;
            }
        }
    }
}
