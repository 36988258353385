/*------------------------------------*\
    # components.header
\*------------------------------------*/

.header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10000;

	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex-shrink: 0;
	padding: calc($unit * 3) calc($unit * 3);
	padding-right: 64px;

	border-bottom: 1px solid $grey-10;
	background-color: $white;

	transition: all 250ms ease-in-out;

	&:has(.header__dropdown) {
		z-index: 9999999;
	}

	@media (--screen-from-med) {
		left: $aside-width-collapsed;
	}

	@media (--screen-from-xlrg) {
		left: $aside-width;
		height: 88px;
		padding: 0 calc($unit * 12);
	}

	/* Account */
	&__account {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: calc($unit * 1);
		padding: calc($unit * 1.5);
		width: 100%;

		text-align: left;

		border: 0;
		border-radius: calc($global-radial * 2);
		background-color: transparent;

		cursor: pointer;
		transition: all 250ms ease-in-out;

		@media (--screen-from-med) {
			width: 270px;
			gap: calc($unit * 2);
		}

		&:hover,
		&.open {
			background-color: $color-navigation;

			& > .icon--expand {
				background-color: $color-text;
			}
		}

		& > .icon--expand {
			transition: all 250ms ease-in-out;
		}

		&__profile {
			display: flex;
			align-items: center;
			flex-shrink: 0;
			justify-content: center;
			width: calc($unit * 10);
			height: calc($unit * 10);

			@mixin type-scale $type-plus-small, 1;
			font-weight: $type-weight-medium;
			color: $white;

			border-radius: $global-rounded;
			background-color: $orange;
			box-shadow: 0 10px 30px 0 color($orange a(0.48));
		}

		&__name {
			overflow: hidden;
			width: 100%;
			white-space: nowrap;

			font-size: calc($type-plus-small * 1px);
			font-weight: $type-weight-medium;
			text-overflow: ellipsis;
			color: $color-text;

			&__wrapper {
				flex-grow: 1;
				min-width: 0;
			}
		}

		&__email {
			overflow: hidden;
			width: 100%;
			white-space: nowrap;

			font-size: calc($type-nano * 1px);
			text-overflow: ellipsis;
			color: $color-text-light;
		}
	}

	/* Dropdown menu for notifications and account */
	&__dropdown {
		position: absolute;
		top: calc(100% + $unit);
		right: 0;

		overflow-y: auto;
		max-height: 430px;

		border: 1px solid $grey-10;
		border-radius: calc($global-radial * 2);
		background-color: $white;
		box-shadow: 0 20px 16px -10px color($color-secondary a(0.12));

		&--account {
			width: 250px;

			&__title {
				padding: calc($unit * 2);
				@mixin type-scale $type-nano, 0.75;
				font-weight: $type-weight-semibold;
				text-transform: uppercase;
				color: $color-text-light;
			}

			&__section {
				padding: calc($unit * 2);

				&:not(:last-child) {
					border-bottom: 1px solid $grey-10;
				}
			}

			li {
				display: flex;
				align-items: center;
				gap: calc($unit * 2);
				padding: calc($unit * 2) calc($unit * 3);

				font-weight: $type-weight-medium;

				border-radius: $global-radial;
				cursor: pointer;

				transition: all 250ms ease-in-out;

				&:hover {
					background-color: $color-navigation;

					& > a {
						color: $color-text;
					}
				}

				& > a,
				& > button {
					display: flex;
					align-items: center;
					gap: calc($unit * 2);
					padding: 0;
					width: 100%;

					line-height: calc($unit * 5);
					text-align: left;
					font-weight: $type-weight-medium;

					border: 0;
					background-color: transparent;

					cursor: pointer;
					transition: all 250ms ease-in-out;
				}

				& > .logout {
					width: 100%;
					color: $color-text-light;
				}
			}
		}
	}

	/* Menu section on the right side */
	&__menu {
		position: relative;

		display: flex;
		align-items: center;
		gap: calc($unit * 3);
		min-width: 0;

		@media (--screen-to-xlrg) {
			flex-grow: 1;
			justify-content: space-between;
		}

		@media (--screen-from-xlrg) {
			margin-left: auto;
		}
	}

	&__points {
		position: relative;

		display: inline-flex;
		padding: 0 calc($unit * 2);
		margin-bottom: $unit;

		font-size: calc($type-nano * 1px);
		line-height: calc($unit * 4.5);
		font-weight: $type-weight-medium;
		color: $white;

		border-radius: calc($global-radial / 2);
		background-color: $color-primary;
		cursor: pointer;

		&:hover {
			.header__points__tooltip,
			.header__points__tooltip__tail {
				display: block;
			}
		}

		&__tooltip {
			position: absolute;
			top: calc(100% + 16px);
			right: 0;
			left: auto;

			display: none;
			width: 250px;
			padding: calc($unit * 2) calc($unit * 3);

			color: $white;

			border-radius: calc($global-radial / 2);
			background-color: $grey-100;
			box-shadow: $shadow-tooltip;

			&__tail {
				position: absolute;
				top: calc(100% + 12px);
				right: 50%;

				display: none;
				width: calc($unit * 2);
				height: calc($unit * 2);

				border-radius: 2px;
				background-color: $grey-100;
				transform: rotate(45deg) translate(50%, -40%);
			}
		}
	}
}
