/*------------------------------------*\
    # components.popover
\*------------------------------------*/

.popover {
	z-index: 10;

	position: absolute;
	right: 0;
	bottom: calc(100% + 8px);

	overflow-y: auto;
	width: 260px;
	max-height: 270px;
	padding: calc($unit * 3);

	border-radius: calc($global-radial * 2);
	background-color: $white;

	/* Custom scroll */
	&:not(textarea)::-webkit-scrollbar-button {
		height: calc($unit * 3);
	}

	&:not(.popover--sidebar) {
		border: 1px solid $grey-10;
		box-shadow: $shadow-big;
	}

	&__list {
		display: flex;
		flex-direction: column;

		& > .item,
		& > .item.internal > ul > li,
		& > .tabs__link {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: calc($unit * 3);
			padding: calc($unit * 3) calc($unit * 2);
			width: 100%;

			font-size: calc($type-plus-small * 1px);
			line-height: calc($unit * 5);
			font-weight: $type-weight-medium;
			color: $color-text;

			cursor: pointer;

			&:not(:last-child) {
				border-bottom: 1px solid $grey-10;
			}

			&:has(.is-disabled) {
				cursor: not-allowed;
			}

			&.internal {
				order: 2;
				padding: 0;
			}

			&.is-active {
				color: $color-primary;

				&:after {
					display: none;
				}
			}

			& > a,
			& > button {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: calc($unit * 3);
				white-space: normal;
				width: 100%;
				padding: 0;

				font-size: calc($type-plus-small * 1px);
				font-weight: $type-weight-medium;
				text-align: left;

				border: 0;
				background-color: transparent;
				cursor: pointer;
				transition: all 200ms ease-in-out;

				& > .icon {
					transition: all 200ms ease-in-out;
				}

				&:not(.btn--link--error) {
					color: $color-text;
				}

				&:hover {
					color: $color-primary;

					& > .icon {
						background-color: $color-primary;
					}
				}
			}
		}
	}

	/* Custom styling */
	&--fullwidth {
		right: 0;
		left: 0;
		width: 100%;
	}

	&--grid-actions {
		right: 20px;
		bottom: -60px;
	}

	/* Positions */
	&--down {
		top: calc(100% + 8px);
		bottom: auto;
	}

	&--down-right {
		top: calc(100% + 8px);
		bottom: auto;
		right: auto;
		left: 0;
	}
}
