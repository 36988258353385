/*------------------------------------*\
    # components.loader
\*------------------------------------*/

.loader {
	&__spinner {
		position: absolute;
		top: 50%;
		left: 50%;

		width: calc($unit * 6);
		height: calc($unit * 6);
		margin: calc(-$unit * 3);

		border: $unit solid $grey-20;
		border-bottom: $unit solid $color-primary;
		border-radius: 50%;
		animation: spinneranimation 0.4s infinite linear;
	}

	&--progress {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: $unit;
		overflow: hidden;
		z-index: 99999;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: -100%;
			width: calc($unit * 50);
			height: 100%;
			background-color: $color-primary;
			animation: loaderprogress 1.6s infinite linear;
		}
	}

	&--reload {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc($unit * 12.5);
		height: calc($unit * 12.5);

		background-color: $color-primary;
		border-radius: $global-rounded;

		&__icon {
			display: inline-block;
			width: calc($unit * 6);
			aspect-ratio: 1;

			background-image: svg-load('../images/loader-arrows.svg');
			background-repeat: no-repeat;
			background-position: center;

			animation: reloadanimation 1s infinite linear;
		}
	}

	&--process {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;
		width: 180px;
		aspect-ratio: 1;
		margin: 0 auto;
		margin-bottom: calc($unit * 10);

		border: 6px solid #f4f7fb;
		border-radius: $global-rounded;
		background-color: $white;

		& > .icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 106px;
			height: 106px;

			border-radius: $global-rounded;
			background-color: $color-primary;

			box-shadow: 0 11.74px 15.65px 0 color(#0047ff a(0.32));

			&:after {
				content: '';

				display: block;
				width: calc($unit * 6);
				height: calc($unit * 6);

				mask-image: svg-load('../images/loader.svg');
				mask-size: calc($unit * 6);
				mask-repeat: no-repeat;
				mask-position: center;
				background-color: $white;
			}
		}

		&.is-loading {
			&:after {
				content: '';

				position: absolute;
				top: -6px;
				right: -6px;
				bottom: -6px;
				left: -6px;

				border: $unit solid transparent;
				border-right: $unit solid $color-primary;
				border-radius: $global-rounded;

				animation: process 2s infinite;
			}
		}

		&.is-success {
			border: 6px solid color($success a(0.24));

			& > .icon {
				background-color: $success;
				box-shadow: 0 11.74px 15.65px 0 color($success a(0.32));

				&:after {
					mask-image: svg-load('../images/check-circle.svg');
				}
			}
		}

		&.has-error {
			border: 6px solid color($error a(0.24));

			& > .icon {
				background-color: $error;
				box-shadow: 0 11.74px 15.65px 0 color($error a(0.32));

				&:after {
					mask-image: svg-load('../images/error.svg');
				}
			}
		}
	}
}

@keyframes spinneranimation {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes reloadanimation {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes loaderprogress {
	0% {
		left: -300px;
	}

	100% {
		left: 100%;
	}
}

@keyframes process {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
