/*------------------------------------*\
    # components.buttondropdown
\*------------------------------------*/

.buttondropdown {
	position: absolute;
	width: calc($unit * 33);
	background-color: #fff;
	border-bottom: none;
	box-shadow: 0 calc($unit / 3) calc($unit - 2px) $shadow-sml;
	z-index: 99999;
	transform: translateX(-50%);

	&__list {
		border-bottom: 1px solid $grey-10;
	}

	&__item {
		display: block;
		padding: calc($unit * 1.5) calc($unit * 2);

		&:hover,
		&:focus {
			background-color: $grey-0;
			cursor: pointer;
			outline: none;
		}

		&--withlink {
			padding: 0;
		}
	}

	&__link {
		display: block;
		padding: calc($unit * 1.5) calc($unit * 2);
	}

	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: transparent;
		z-index: 99;
	}

	/* Positions */
	&--left {
		transform: translate(calc(-100% - 21px), -50%);
	}
}
