/*------------------------------------*\
    # components.card
\*------------------------------------*/

.card {
	border: 1px solid $grey-10;
	border-radius: calc($global-radial * 2);

	&.is-expanded {
		box-shadow: $shadow-sml;
	}

	&:has(.card__header) {
		& > .card__body {
			padding-top: 0;
		}
	}

	/* Elements */
	&__header {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: calc($unit * 6) calc($unit * 3.5);

		border: 0;
		background-color: transparent;
		outline: 0;

		@media (--screen-from-med) {
			padding: calc($unit * 6);
		}

		&--expandable {
			cursor: pointer;

			&:focus-visible {
				border-radius: calc($global-radial * 2);
				outline: 2px solid $color-text;
				outline-offset: 2px;
			}
		}
	}

	&__body {
		padding: calc($unit * 3.5);

		@media (--screen-from-med) {
			padding: calc($unit * 6);
		}

		&:empty {
			display: none;
		}

		/* Customs */
		&--no-bottom-padding {
			padding-bottom: 0;
		}

		/* Nested cards */
		& > .card {
			border: 0;
			margin-bottom: calc($unit * 4);

			& > .card__header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: calc($unit * 3.5) calc($unit * 4);

				border-radius: $global-radial;
				background-color: var(--color-sidebar-final);
			}

			& > .card__body {
				padding: calc($unit * 4);
				padding-bottom: calc($unit * 2);
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: calc($unit * 5);
		padding: calc($unit * 4) calc($unit * 6);

		border-radius: 0 0 calc($global-radial * 2) calc($global-radial * 2);
		background-color: var(--color-sidebar-final);
	}

	&__upload {
		&__item {
			padding: calc($unit * 4) 0;
			border-top: 1px solid $grey-10;

			&:last-child {
				border-bottom: 1px solid $grey-10;
			}
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: nowrap;
			width: 100%;
			background-color: transparent;
			border: 0;
			cursor: pointer;
		}

		&__body {
			padding-top: calc($unit * 8);
			padding-bottom: calc($unit * 8);
		}
	}

	/* Customs */
	&--equipment {
		padding: calc($unit * 4) calc($unit * 4);
		margin-bottom: calc($unit * 6);
		transition: box-shadow 250ms ease-in-out;

		@media (--screen-from-med) {
			padding: calc($unit * 4) calc($unit * 8);
		}

		&:hover {
			&:not(.is-expanded) {
				box-shadow: $shadow-big;
			}
		}

		&.is-expanded {
			box-shadow: none;
		}

		.equipment__details__aside__body {
			border-top: 1px solid $grey-10;
			border-radius: calc($global-radial * 2);
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 0;
			width: 100%;

			text-align: left;

			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__empty-state {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			padding-top: calc($unit * 6);

			&__img {
				flex-shrink: 0;
				width: 130px;

				aspect-ratio: 1;
				background-image: url('../../img/emptystate.png');
				background-size: 100%;
			}
		}
	}
}
