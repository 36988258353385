/*------------------------------------*\
    # utils.type
\*------------------------------------*/

.type {
	/* Font sizes */
	&--h1 {
		@mixin type-scale $type-huge, 3;
	}

	&--h2 {
		@mixin type-scale $type-big, 2.75;
	}

	&--h3 {
		@mixin type-scale $type-xxlarge, 2;
	}

	&--h4 {
		@mixin type-scale $type-xlarge, 1.5;
	}

	&--h5 {
		@mixin type-scale $type-base-alt, 1.25;
	}

	&--h6 {
		@mixin type-scale $type-base, 1;
	}

	&--p1 {
		font-size: calc($type-plus-small * 1px);
		line-height: calc($unit * 4.5);
		font-weight: $type-weight-regular;

		@media (--screen-from-med) {
			font-size: calc($type-base * 1px);
			line-height: calc($unit * 6);
		}

		&--medium {
			font-weight: $type-weight-medium;
		}
	}

	&--p2 {
		font-size: calc($type-plus-small * 1px);
		line-height: calc($unit * 5);
		font-weight: $type-weight-regular;

		&--medium {
			font-weight: $type-weight-medium;
		}
	}

	&--p3 {
		font-size: calc($type-xsmall * 1px);
		line-height: calc($unit * 4);
		font-weight: $type-weight-regular;

		&--medium {
			font-weight: $type-weight-medium;
		}
	}

	&--p4 {
		font-size: calc($type-nano * 1px);
		line-height: calc($unit * 3.5);
		font-weight: $type-weight-regular;

		&--medium {
			font-weight: $type-weight-medium;
		}
	}

	&--title {
		font-size: calc($type-nano * 1px);
		line-height: calc($unit * 3);
		font-weight: $type-weight-semibold;
		letter-spacing: 0.5px;
		color: $color-text-light;
	}

	/* Font weights */
	&--wgt {
		&--thin {
			font-weight: $type-weight-thin;
		}

		&--light {
			font-weight: $type-weight-light;
		}

		&--regular {
			font-weight: $type-weight-regular;
		}

		&--medium {
			font-weight: $type-weight-medium;
		}

		&--semibold {
			font-weight: $type-weight-semibold;
		}

		&--bold {
			font-weight: $type-weight-bold;
		}
	}

	/* Type colors */
	&--color {
		&--text {
			color: $color-text;

			/*Grey 30*/
			&--opaque {
				color: $grey-30;
			}

			/*Grey 60*/
			&--light {
				color: $color-text-light;
			}

			/*Grey 80*/
			&--secondary {
				color: $grey-80;
			}
		}

		&--primary {
			color: $color-primary;
		}

		&--error {
			color: $error;
		}

		&--success {
			color: $success;
		}
	}

	/* Type case */
	&--uppercase {
		text-transform: uppercase;
		letter-spacing: 0.05em;
	}

	/* Type positioning */
	&--center {
		text-align: center;
	}

	&--left {
		text-align: left;
	}

	&--right {
		text-align: right;
	}

	/* Type wrapping */
	&--nowrap {
		white-space: nowrap;
	}

	&--break-word {
		word-break: break-word;
	}

	&--underline {
		text-decoration: underline;
	}

	&--linethrough {
		text-decoration: line-through;
	}

	&--validation {
		font-size: calc($type-xsmall * 1px);
		line-height: calc($unit * 4);
		color: $error;

		& * {
			font-size: calc($type-xsmall * 1px);
			line-height: calc($unit * 4);
			color: $error;
		}

		& > button {
			padding: 0;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		/* Use for validation list */
		&__wrapper {
			display: flex;
			flex-direction: column;
			gap: $unit;

			& > li {
				display: flex;
				align-items: center;
				gap: $unit;
			}
		}
	}

	&--is-empty {
		&:empty {
			&:before {
				content: '---';
				color: $grey-40;
			}
		}
	}
}
