/*------------------------------------*\
    # components.filter
\*------------------------------------*/

.filter {
	padding-bottom: calc($unit * 6);

	.rc-menu-submenu-title {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		line-height: calc($unit * 6 + 4px);
	}

	&__toolbar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: calc($unit * 3);
		margin-bottom: calc($unit * 6);

		@media (--screen-to-med) {
			align-items: flex-start;
			flex-direction: column;
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
			gap: calc($unit * 2);
		}

		&__selected-filter {
			display: inline-flex;
			align-items: center;
			gap: calc($unit * 1.5);
			padding: calc($unit * 2) calc($unit * 2.5);

			font-size: calc($type-xsmall * 1px);
			line-height: calc($unit * 4);
			white-space: nowrap;
			color: $color-text;

			border: 0;
			border-radius: $global-rounded;
			background-color: $grey-10;
		}
	}

	/* Filters button */
	&__select {
		&:focus-visible {
			border-radius: $global-rounded;
			outline: 2px solid $color-text;
			outline-offset: 2px;
		}

		& > .rc-menu {
			border-bottom: 0;
			background-color: transparent;

			& > .rc-menu-submenu {
				overflow: hidden;

				border: 1px solid $grey-10;
				border-radius: $global-rounded;

				transition: all 250ms ease-in-out;

				&.rc-menu-submenu-open,
				&.rc-menu-submenu-active {
					border-color: color($grey-10 blackness(+50%));
					background-color: transparent;
					cursor: pointer;

					& > .rc-menu-submenu-title {
						background-color: transparent;
					}

					.rc-menu-link > .rc-menu-submenu {
						cursor: pointer;

						&.rc-menu-submenu-active {
							background-color: $grey-10;

							& > .rc-menu-submenu-title {
								background-color: $grey-10;
							}
						}

						& > .rc-menu-submenu-title {
							display: flex;
							align-items: center;
							gap: calc($unit * 1.5);
							height: calc($unit * 8);

							padding: 0;
							padding-right: calc($unit * 3);
							padding-left: calc($unit * 2.5);

							@mixin type-scale $type-xsmall, 1;
							font-weight: $type-weight-medium;
							color: $color-text;

							transition: all 250ms ease-in-out;

							& > .rc-menu-submenu-arrow {
								order: -1;

								width: calc($unit * 3);
								height: calc($unit * 3);

								background-color: $color-text;
								mask-image: svg-load('../images/add-line.svg');
								mask-size: calc($unit * 3);
								mask-position: center;
							}
						}
					}
				}

				&.rc-menu-submenu-disabled {
					opacity: 0.5;
					cursor: default;
				}

				& > .rc-menu-submenu-title {
					display: flex;
					align-items: center;
					gap: calc($unit * 1.5);
					height: calc($unit * 8);

					padding: 0;
					padding-right: calc($unit * 3);
					padding-left: calc($unit * 2.5);

					@mixin type-scale $type-xsmall, 1;
					font-weight: $type-weight-medium;
					color: $color-text;

					transition: all 250ms ease-in-out;

					& > .rc-menu-submenu-arrow {
						order: -1;

						width: calc($unit * 3);
						height: calc($unit * 3);

						background-color: $color-text;
						mask-image: svg-load('../images/add-line.svg');
						mask-size: calc($unit * 3);
						mask-position: center;
					}

					& > .filter__counter {
						display: inline-flex;
						align-items: center;
						justify-content: center;
						padding: 0 $unit;
						min-width: calc($unit * 3.5);
						height: calc($unit * 3.5);

						font-size: calc($type-tiny * 1px);
						line-height: calc($unit * 3.5);
						color: $white;

						border-radius: $global-rounded;
						background-color: $color-primary;
					}
				}
			}
		}

		&--columns {
			& > .rc-menu {
				&:focus-visible {
					border-radius: $global-rounded;
					outline: 2px solid $color-text;
					outline-offset: 2px;
				}

				& > .rc-menu-submenu {
					& > .rc-menu-submenu-title {
						& > .rc-menu-submenu-arrow {
							mask-image: svg-load('../images/columns.svg');
						}
					}
				}
			}
		}

		&--save {
			& > .rc-menu > .rc-menu-submenu > .rc-menu-submenu-title > .rc-menu-submenu-arrow {
				mask-image: none;
				background-color: transparent;
				background-image: svg-load('../images/save.svg');
				background-size: 100%;
			}
		}

		&--native {
			appearance: none;

			display: inline-flex;
			height: calc($unit * 8);
			padding-left: calc($unit * 7);
			padding-right: calc($unit * 2);

			font-size: calc($type-xsmall * 1px);
			line-height: calc($unit * 8);
			font-weight: $type-weight-medium;
			text-overflow: ellipsis;
			color: $color-text;

			border: 1px solid $grey-10;
			border-radius: $global-rounded;
			background-image: svg-load('../images/add.svg');
			background-size: calc($unit * 3);
			background-repeat: no-repeat;
			background-position: calc($unit * 2.5) center;

			&:hover,
			&:focus {
				outline: 0;
				border-color: $grey-60;
			}
		}
	}

	&--selected {
		display: inline-block;
		width: calc($unit * 1.5);
		height: calc($unit * 1.5);
		vertical-align: 1px;
		margin-left: calc($unit * 2);

		border-radius: $global-rounded;
		background-color: $color-primary;
	}

	/* Date filter button */
	&__date {
		& > .rc-menu-horizontal {
			display: flex;
			align-items: center;
			height: calc($unit * 8);
			padding: 0 calc($unit * 2.5);

			border-radius: $global-rounded;
			background-color: $grey-10;

			cursor: pointer;
			transition: all 250ms ease-in-out;

			&:hover {
				background-color: $grey-20;
			}

			&:focus-visible {
				border-radius: $global-rounded;
				outline: 2px solid $color-text;
				outline-offset: 2px;
			}

			& > .rc-menu-submenu {
				z-index: 9999;
				background-color: transparent;
				border-bottom: 0;

				&.rc-menu-submenu-active {
					border-bottom: 0;

					& > .rc-menu-submenu-title {
						background-color: transparent;
					}
				}

				& > .rc-menu-submenu-title {
					display: flex;
					align-items: center;
					gap: $unit;
					padding: 0;

					font-size: calc($type-xsmall * 1px);
					font-weight: $type-weight-medium;
					color: $color-text;

					&:before {
						content: 'Date Range:';

						display: block;
						padding-left: calc($unit * 4);

						font-weight: $type-weight-regular;

						background-image: svg-load('../images/calendar.svg', stroke: $color-text);
						background-size: calc($unit * 3);
						background-repeat: no-repeat;
						background-position: left center;
					}
				}
			}
		}
	}

	&__search {
		max-width: 320px;
		width: 100%;
	}

	&__toggle {
		button {
			padding: 0 $unit;
		}

		@media (--screen-from-sml) {
			display: none;
		}
	}
}
