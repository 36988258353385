/*------------------------------------*\
    # plugins.ui-calendar
\*------------------------------------*/

.ui-widget.ui-widget-content {
	border: 1px solid #d9d9d9 !important;
    box-shadow: 0 0 4px #d9d9d9 !important;
}

.ui-calendar {
	z-index: 99;
}

.ui-flexcal {
	padding: 0 !important;
}

.ui-datepicker-header {
	padding: calc($unit * 2) !important;
	background-color: $grey-0 !important;
	border: none !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
	border: none !important;
	background-color: transparent !important;
	text-align: center !important;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	width: calc($unit * 5);
	height: calc($unit * 5);
	padding: 5px 0;
	color: $color-primary !important;
	border: none !important;
	border-radius: $global-rounded !important;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	top: calc($unit * 1.5) !important;
}

.ui-datepicker .ui-datepicker-prev {
	.ui-icon,
	.ui-widget-content .ui-icon {
		background-image: svg-load('../images/ic_chevron_left_black_24px.svg', fill: #7f7f7f) !important;
		background-repeat: no-repeat;
		background-position: center;
		background-size: calc($unit * 3);
	}
}

.ui-datepicker .ui-datepicker-next {
	.ui-icon,
	.ui-widget-content .ui-icon {
		background-image: svg-load('../images/ic_chevron_right_black_24px.svg', fill: #7f7f7f) !important;
		background-repeat: no-repeat;
		background-position: center;
		background-size: calc($unit * 3);
	}
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
	border: none !important;
}

.ui-flexcal [dir=rtl] .go.ui-state-hover.ui-datepicker-prev {
	right: 2px !important;
}

.ui-flexcal [dir=rtl] .go.ui-state-hover.ui-datepicker-next {
	left: 2px !important;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active, .ui-button:active,
.ui-button.ui-state-active:hover {
	background-color: $color-primary !important;
	color: #fff !important;
	border: none !important;
	border-radius: $global-rounded !important;
}