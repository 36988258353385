/*------------------------------------*\
    # components.leads
\*------------------------------------*/

.leads {
	margin: 0 auto;
	padding-bottom: calc($unit * 18);
	width: 100%;
	max-width: 880px;

	&__header {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
		gap: calc($unit * 4);

		margin-bottom: calc($unit * 10);
	}

	&__footer {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;

		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: calc($unit * 3) calc($unit * 4.5);
		gap: calc($unit * 3);

		border-top: 1px solid $color-navigation;
		background-color: $white;

		@media (--screen-from-med) {
			padding: calc($unit * 3) calc($unit * 12);
		}
	}
}
