/*------------------------------------*\
    # plugins.colorpicker
\*------------------------------------*/

.colorpicker {
	&__wrapper {
		display: flex;
		align-items: center;
	}

	/* Aside Elements */
	&__aside {
		display: flex;
		align-items: center;
		gap: calc($unit * 3);
		padding: 5px;
		padding-right: calc($unit * 4);

		border: 1px solid $grey-10;
		border-right: 0;
		border-radius: calc($global-radial / 2) 0 0 calc($global-radial / 2);
		background-color: #f8fafc;

		@media (--screen-from-med) and (--screen-to-lrg) {
			gap: calc($unit * 1.5);
			padding-right: calc($unit * 2);
		}
	}

	&__preview {
		width: calc($unit * 8);
		height: calc($unit * 8);
		border-radius: calc($global-radial / 2);

		@media (--screen-from-med) and (--screen-to-lrg) {
			width: calc($unit * 4);
		}
	}

	&__label {
		font-size: calc($type-plus-small * 1px);
		line-height: calc($unit * 5);
		font-weight: $type-weight-medium;
		color: $grey-60;
		white-space: nowrap;
	}

	/* Main Elements */
	&__main {
		position: relative;
		flex-grow: 1;

		& > button {
			position: absolute;
			top: 50%;
			right: calc($unit * 3);
			transform: translateY(-50%);
		}
	}

	&__input {
		width: 100%;
		padding: 0 calc($unit * 4);
		height: calc($unit * 11);

		color: $grey-60;

		border: 1px solid $grey-10;
		border-radius: 0 calc($global-radial / 2) calc($global-radial / 2) 0;

		&:focus {
			outline: 0;
			border-color: $color-primary;
		}
	}

	&__picker {
		position: absolute;
		left: 0;
		bottom: calc(100% - 12px);

		padding: calc($unit * 4);

		border-radius: $global-radial;
		background-color: white;
		box-shadow: $shadow-big;

		&:after {
			content: '';

			position: absolute;
			bottom: -7px;
			left: 15px;

			width: calc($unit * 4);
			height: calc($unit * 4);

			border-radius: 2px;
			background-color: $white;
			transform: rotate(45deg);
		}

		.colorpicker {
			z-index: 1;
		}
	}
}

.react-colorful {
	gap: calc($unit * 4);

	&__saturation {
		border-radius: calc($global-radial / 2) !important;
	}

	&__hue {
		height: calc($unit * 3) !important;
		border-radius: $global-rounded !important;
	}

	&__pointer {
		width: calc($unit * 8) !important;
		height: calc($unit * 8) !important;
		border: 1px solid #dfe3e8 !important;
		background-color: $white !important;
		box-shadow: none !important;
	}

	&__pointer-fill {
		top: 50% !important;
		left: 50% !important;
		width: calc($unit * 4) !important;
		height: calc($unit * 4) !important;
		transform: translate(-50%, -50%);
	}
}
