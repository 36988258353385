/*------------------------------------*\
    # components.equipment-card
\*------------------------------------*/

.equipment-card {
	display: flex;
	flex-direction: column;
	padding: calc($unit * 4);
	padding-bottom: calc($unit * 6);

	border: 1px solid $grey-10;
	border-radius: calc($global-radial * 1.5);
	background-color: $white;

	&__body {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__thumbnail {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding-top: 100%;
		margin-bottom: calc($unit * 4);

		border: 0;
		border-radius: calc($global-radial / 2);
		background-size: cover;
		cursor: pointer;

		&__badge {
			&:after {
				z-index: 2;

				position: absolute;
				left: 50%;
				bottom: calc($unit * 2);
				transform: translateX(-50%);

				display: inline-flex;
				align-items: center;
				justify-content: center;
				padding: calc($unit * 2) calc($unit * 4);
				white-space: nowrap;

				font-size: calc($type-nano * 1px);
				line-height: calc($unit * 3);
				font-weight: $type-weight-semibold;
				letter-spacing: 0.05px;
				text-transform: uppercase;
				color: $white;

				border-radius: calc($global-radial / 2);
			}

			&.out-of-stock,
			&.not-available {
				&:before {
					content: '';

					opacity: 0.7;
					z-index: 1;

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					background-color: $white;
				}
			}

			&.out-of-stock,
			&.out-of-stock.added {
				&:after {
					content: 'Out of Stock';
					background-color: $color-primary;
				}
			}

			&.not-available {
				&:after {
					content: 'Not available';
					background-color: $error;
				}
			}

			&.added {
				&:after {
					content: 'Added';
					background-color: $success;
				}
			}
		}
	}

	&__title {
		width: 100%;
		padding: 0;

		font-size: calc($type-base-alt * 1px);
		line-height: calc($unit * 6);
		font-weight: $type-weight-medium;
		word-break: break-word;
		text-align: left;

		border: 0;
		background-color: transparent;
		cursor: pointer;
	}

	&__description {
		display: -webkit-box;
		overflow: hidden;

		font-size: calc($type-xsmall * 1px);
		line-height: calc($unit * 4);
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		color: $grey-50;
	}

	&__amount {
		margin-top: auto;
		font-size: calc($type-xlarge * 1px);
		line-height: calc($unit * 7.5);
		font-weight: $type-weight-medium;
	}
}
