/*------------------------------------*\
    # utils.visibility
\*------------------------------------*/

.hide {
	&--to {
		&--sml {
			@media (--screen-to-sml) {
				display: none;
			}
		}

		&--med {
			@media (--screen-to-med) {
				display: none;
			}
		}

		&--lrg {
			@media (--screen-to-lrg) {
				display: none;
			}
		}

		&--xxlrg {
			@media (--screen-to-xxlrg) {
				display: none;
			}
		}
	}

	&--from {
		&--sml {
			@media (--screen-from-sml) {
				display: none;
			}
		}

		&--med {
			@media (--screen-from-med) {
				display: none;
			}
		}
	}
}
