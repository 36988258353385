/*------------------------------------*\
    # components.chips
\*------------------------------------*/

.chips {
	display: inline-flex;
	align-items: center;
	gap: calc($unit * 1.5);
	padding-right: calc($unit * 3);
	padding-left: calc($unit * 2.5);
	height: calc($unit * 8);

	font-size: calc($type-xsmall * 1px);
	line-height: calc($unit * 8);
	font-weight: $type-weight-medium;
	color: $color-text;

	border-radius: $global-rounded;

	&--search {
		padding-right: calc($unit * 6);
		padding-left: calc($unit * 7);

		border: 1px solid $grey-10;
		background-image: svg-load('../images/search.svg');
		background-size: calc($unit * 3);
		background-position: left calc($unit * 2.5) center;
		background-repeat: no-repeat;

		cursor: text;

		&:hover,
		&:focus {
			outline: 0;
			border-color: $grey-60;
		}
	}
}
