/*------------------------------------*\
    # modules.datatooltip
\*------------------------------------*/

[data-tooltip] {
	position: relative;
	cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
	z-index: 9999;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

[data-tooltip]:before {
	content: attr(data-tooltip);

	position: absolute;
	bottom: calc(100% + calc($unit * 2));
	left: 50%;

	width: 200px;
	padding: calc($unit * 2) calc($unit * 3);
	white-space: normal;

	font-family: $ff-sans;
	font-size: calc($type-xsmall * 1px);
	font-style: normal;
	text-align: center;
	font-weight: $type-weight-medium;
	line-height: calc($unit * 4);
	letter-spacing: 0;
	text-transform: none;
	color: $white;

	border-radius: calc($global-radial / 2);
	background-color: $color-secondary;
	transform: translateX(-50%);
	box-shadow: $shadow-tooltip;

	direction: ltr;
}

[data-tooltip]:after {
	content: '';

	position: absolute;
	bottom: calc(100% + 3px);
	left: 50%;
	transform: translateX(-50%);

	width: 0;

	font-size: 0;
	line-height: 0;

	border-top: 5px solid $color-secondary;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	visibility: visible;
	opacity: 1;
}

.datatooltip {
	/* Width */
	&--auto {
		&[data-tooltip] {
			&:before {
				width: auto;
			}
		}
	}

	&--w {
		&--100 {
			&[data-tooltip]:before {
				width: 100px;
				white-space: normal;
			}
		}

		&--150 {
			&[data-tooltip]:before {
				width: 150px;
				white-space: normal;
			}
		}

		&--200 {
			&[data-tooltip]:before {
				width: 200px;
				white-space: normal;
			}
		}

		&--230 {
			&[data-tooltip]:before {
				width: 230px;
				white-space: normal;
			}
		}
	}

	/* Position */
	&--right {
		&[data-tooltip]:before {
			top: 50%;
			right: auto;
			bottom: auto;
			left: calc(100% + 11px);
			transform: translate(0%, -50%);
		}

		&[data-tooltip]:after {
			top: 50%;
			bottom: auto;
			left: calc(100% + 6px);

			border-top: 5px solid transparent;
			border-right: 5px solid $color-text;
			border-bottom: 5px solid transparent;
			border-left: 0;

			transform: translate(0, -50%);
		}
	}

	&--down {
		&[data-tooltip]:before {
			top: calc(100% + 6px);
			bottom: auto;
		}

		&[data-tooltip]:after {
			border-top: 0 solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid $color-dark;
			border-left: 5px solid transparent;
			left: 50%;
			top: calc(100% + 2px);
		}
	}

	&--left {
		&[data-tooltip]:before {
			top: 50%;
			right: calc(100% + 8px);
			bottom: auto;
			left: auto;
			transform: translate(0, -50%);
		}

		&[data-tooltip]:after {
			left: auto;
			right: calc(100% + 3px);
			top: 50%;
			border-top: 5px solid transparent;
			border-right: 0;
			border-bottom: 5px solid transparent;
			border-left: 5px solid $color-text;
			transform: translateY(-50%);
		}
	}

	&--top-right {
		&[data-tooltip]:before {
			left: -4px;
			transform: translate(0, 0);
		}
	}

	&--top-left {
		&[data-tooltip]:before {
			left: auto;
			right: 0;
			transform: translate(0, 0);
		}
	}

	&--down-left {
		&[data-tooltip]:before {
			top: calc(100% + 6px);
			right: 0;
			bottom: auto;
			left: auto;
			transform: translate(0);
		}

		&[data-tooltip]:after {
			top: calc(100% + 2px);
			border-top: 0 solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid $color-dark;
			border-left: 5px solid transparent;
		}
	}

	&--down-right {
		&[data-tooltip]:before {
			top: calc(100% + 6px);
			right: auto;
			bottom: auto;
			left: 0;
			transform: translate(0);
		}

		&[data-tooltip]:after {
			top: calc(100% + 2px);
			border-top: 0 solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid $color-dark;
			border-left: 5px solid transparent;
		}
	}

	/* Wrapping */
	&--no-wrap {
		&[data-tooltip]:before {
			white-space: nowrap;
		}
	}

	/* Fake tooltip for Customer and Recurring grid.
    Default one won't work because of grid overflow. */
	&--fake {
		position: relative;
		background-color: $color-dark;
		padding: calc($unit * 2) calc($unit * 3);

		font-family: $ff-sans;
		font-size: calc($type-xsmall * 1px);
		font-style: normal;
		text-align: center;
		font-weight: $type-weight-medium;
		line-height: calc($unit * 4);
		text-transform: none;
		color: $white;

		border-radius: $global-radial;

		&:after {
			content: '';

			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);

			width: 0;
			border-top: 5px solid $color-dark;
			border-right: 5px solid transparent;
			border-left: 5px solid transparent;
		}
	}
}
