.flex {
	&--primary {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
	}

	&--secondary {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	/* Grow */
	&--grow {
		&--1 {
			flex-grow: 1;
			min-width: 0;
		}
	}

	/* Grow */
	&--no-shrink {
		flex-shrink: 0;
	}

	/* Wrapping */
	&--nowrap {
		flex-wrap: nowrap;
	}

	/* Aligning */
	&--top {
		align-items: flex-start;
	}

	&--right {
		justify-content: flex-end;
	}

	&--bottom {
		align-items: flex-end;
	}

	&--center {
		justify-content: center;
	}

	/* Direction */
	&--column {
		flex-direction: column;
	}

	/* Gap */
	&--gap {
		&--tny {
			gap: $unit;
		}

		&--sml {
			gap: calc($unit * 2);

			&--alt {
				gap: calc($unit * 3);
			}
		}

		&--med {
			gap: calc($unit * 4);
		}

		&--lrg {
			gap: calc($unit * 6);
		}

		&--xlrg {
			gap: calc($unit * 8);
		}

		&--xxlrg {
			gap: calc($unit * 10);
		}
	}
}
