/*------------------------------------*\
    # components.ticket
\*------------------------------------*/

.ticket {
	&__notes {
		margin-bottom: calc($unit * 14);

		&__avatar {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc($unit * 8);
			height: calc($unit * 8);

			font-size: calc($type-plus-small * 1px);
			line-height: calc($unit * 5);
			font-weight: $type-weight-medium;
			color: $white;

			border-radius: $global-rounded;
			background-color: $orange;
			box-shadow: 0 10px 30px 0 color($orange a(0.48));
		}

		&__body {
			padding-left: calc($unit * 10.5);
		}

		&__texteditor {
			position: sticky;
			bottom: calc($unit * 6);

			display: flex;
			flex-direction: column;
			overflow: hidden;

			border: 1px solid $grey-10;
			border-radius: calc($global-radial * 1.8);
			background-color: $white;

			&:has(textarea:focus) {
				border-color: $color-primary;
			}

			& > textarea {
				padding: calc($unit * 3) calc($unit * 4);
				padding-bottom: 0;
				width: 100%;

				border: 0;

				&:focus {
					outline: none;
				}
			}

			&__footer {
				display: flex;
				justify-content: flex-end;
				padding: calc($unit * 4) calc($unit * 3);
			}
		}
	}
}
