/*------------------------------------*\
    # modules.validation
\*------------------------------------*/

.validation {
	display: flex;
	flex-direction: column;
	gap: calc($unit * 1.5);
	padding: calc($unit * 1.5) calc($unit * 2.5);

	@mixin type-scale $type-xsmall, 1;

	border: 1px solid $error;
	border-radius: $global-radial;
	background-color: color($error a(0.1));

	&__list {
		padding-left: calc($unit * 3);
		list-style: disc;
	}
}
