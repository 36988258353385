/*------------------------------------*\
    # components.public
\*------------------------------------*/

.public {
    &__container {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        padding: calc($unit * 2);
        
        @media (--screen-from-med) {
            padding: calc($unit * 4);
        }
    }
}