/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector. 
 */

*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	@mixin typomatic-init;
	font-size: 100%;
	height: 100%;
	box-sizing: border-box;
}

body {
	min-height: 100%;
	overflow-x: hidden;
	margin: 0;

	@mixin type-scale $type-plus-small, 1;
	font-family: $ff-sans;
	color: $color-text-dark;
}

img {
	max-width: 100%;
	vertical-align: middle;
}

svg {
	max-width: 100%;
}

button,
input,
select {
	font-family: $ff-sans;
}
