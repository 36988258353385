/*------------------------------------*\
    # components.pagination
\*------------------------------------*/

.pagination {
	display: flex;
	align-items: center;
	gap: calc($unit * 1.5);

	@media (--screen-to-xsml) {
		width: 100%;
	}

	&__item {
		& > a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc($unit * 9);
			height: calc($unit * 9);

			font-size: calc($type-xsmall * 1px);
			line-height: calc($unit * 4);
			font-weight: $type-weight-regular;
			color: $color-text;

			border-radius: calc($global-radial - 2px);
			border: 1px solid $grey-10;
			background-color: transparent;

			transition: all 250ms ease-in-out;

			@media (--screen-to-xsml) {
				flex-grow: 1;
			}

			&:hover {
				border-color: $grey-20;
			}
		}

		&.active {
			& > a {
				color: $color-primary;
				font-weight: $type-weight-bold;
			}
		}

		&.disabled {
			& > a {
				opacity: 0.5;
				cursor: default;
			}
		}

		[aria-label='Go to first page'],
		[aria-label='Go to previous page'],
		[aria-label='Go to next page'],
		[aria-label='Go to last page'] {
			color: transparent;
			background-size: calc($unit * 4);
			background-position: center;
			background-repeat: no-repeat;
		}

		[aria-label='Go to first page'] {
			background-image: svg-load('../images/arrow-left.svg');
		}

		[aria-label='Go to previous page'] {
			background-image: svg-load('../images/chevron-left.svg');
		}

		[aria-label='Go to next page'] {
			background-image: svg-load('../images/chevron-right.svg');
		}

		[aria-label='Go to last page'] {
			background-image: svg-load('../images/arrow-right.svg');
		}
	}
}
