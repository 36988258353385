/*------------------------------------*\
    # utils.custom
\*------------------------------------*/

/*
    Scroll bar style
    -Working only on chrome    
    -all except textarea because of chrome know issue with scrollbar cursors
*/

*:not(textarea)::-webkit-scrollbar {
    width: calc($unit * 1.5);
    height: calc($unit * 1.5);
    background-color: rgba(0, 0, 0, 0);
    border-radius: 100px;
}

*:not(textarea)::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

*:not(textarea)::-webkit-scrollbar-thumb {
    background: #c8cfd5;
    border-radius: 100px;
}
*:not(textarea)::-webkit-scrollbar-thumb:active {
    background: #acb2b7;
    border-radius: 100px;
}

/* add vertical min-height & horizontal min-width */
*:not(textarea)::-webkit-scrollbar-thumb:vertical {
    min-height: 10px;
}

*:not(textarea)::-webkit-scrollbar-thumb:horizontal {
    min-width: 10px;
}