/*------------------------------------*\
    # components.gateway
\*------------------------------------*/

.gateway {
	&__top {
		position: relative;
		box-shadow: $shadow-big;
		background-color: white;

		&__item {
			padding: calc($unit * 4);
			padding-bottom: calc($unit * 2);

			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;

			@media (--screen-from-med) {
				flex-wrap: nowrap;
				border-bottom: 1px solid $grey-10;
			}

			&:last-child {
				border-bottom: 0px;
			}
		}

		&__separator {
			width: 100px;
			position: relative;
			height: 40px;

			&:before {
				content: '';
				display: block;
				width: 1px;
				background-color: $grey-10;

				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
			}
		}
	}

	&__note {
		&__wrap {
			position: relative;
			display: inline-block;
			padding: $unit;

			height: calc($unit * 6 + 2px);
			line-height: calc($unit * 6 + 2px);

			border-radius: $global-radial;

			cursor: pointer;

			&[disabled] {
				pointer-events: all;
				cursor: not-allowed;

				.icon {
					opacity: 0.5;
				}
			}
		}
	}

	&--has-note {
		width: calc($unit * 2);
		height: calc($unit * 2);
		border-radius: $global-rounded;
		background-color: $color-primary;

		position: absolute;
		top: -$unit;
		right: -$unit;
	}

	&__list {
		&__item {
			z-index: 99;

			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: $unit calc($unit * 2);
			margin-right: calc(-$unit * 2);
			margin-left: calc(-$unit * 2);

			background-color: $grey-0;
			box-shadow: 0 -12px 0 0 $grey-0;

			cursor: pointer;

			@media (--screen-from-med) {
				position: sticky;
				top: 0;

				padding: $unit calc($unit * 4);
				margin-right: calc(-$unit * 4);
				margin-left: calc(-$unit * 4);
			}

			@media (--screen-from-sml) {
				align-items: center;
			}
		}

		&__radio-button {
			opacity: 0;
			position: absolute;
			width: 0;

			& + label {
				position: relative;
				display: inline-block;
				width: 120px;

				padding: calc($unit * 1.5) calc($unit * 2.5);
				padding-left: calc($unit * 8.5);

				font-weight: $type-weight-medium;
				color: $grey-20;

				border-radius: $global-rounded;
				cursor: pointer;

				&:before {
					content: '';
					display: inline-block;

					width: calc($unit * 4.5);
					height: calc($unit * 4.5);
					border: 2px solid $grey-20;
					border-radius: $global-rounded;

					position: absolute;
					left: calc($unit * 2);
					top: 50%;
					transform: translateY(-50%);
				}
			}

			&:checked {
				& + label {
					width: auto;
					background-color: $color-primary;
					color: #ffffff;
					font-weight: $type-weight-regular;

					&:before {
						background-color: $white;
						mask-image: svg-load('../images/check-circle.svg');
						mask-size: calc($unit * 4.5);
						mask-repeat: no-repeat;

						border: 0;
					}
				}
			}

			&:focus {
				& + label {
					/* &:before {
                        box-shadow: 0 0 10px rgba(0,0,0,0.4);
                    } */
				}
			}

			&:disabled {
				& + label {
					opacity: 0.4;
					cursor: not-allowed;
				}
			}

			&__wrapper {
				margin-right: calc($unit * 4);

				@media (--screen-to-sml) {
					margin-bottom: calc($unit * 2);
				}
			}
		}
	}
}
