/*------------------------------------*\
    # components.login
\*------------------------------------*/

.form {
	&__group {
		margin-bottom: calc($unit * 6);

		&__header {
			display: flex;
			align-items: center;
			gap: $unit;
			margin-bottom: $unit;
		}

		&__label {
			display: block;

			font-size: calc($type-nano * 1px);
			line-height: calc($unit * 3.5);
			letter-spacing: 0.1px;
			text-transform: uppercase;
			font-weight: $type-weight-semibold;
			color: $grey-50;
		}

		&__required {
			@mixin type-scale $type-nano, 0.5;
			color: $error;

			&[data-tooltip] {
				&:before {
					width: auto;
				}
			}
		}
	}
}
