/*------------------------------------*\
    # utils.icon
\*------------------------------------*/

.icon {
	position: relative;

	display: inline-block;
	flex-shrink: 0;
	aspect-ratio: 1;

	background-repeat: no-repeat;
	background-position: center;

	&.is-rotated {
		transform: rotate(90deg);
	}

	/*
    Icon sizes
    */

	/* 12x12 */
	&--tny {
		width: calc($unit * 3);
		mask-size: calc($unit * 3);
		background-size: calc($unit * 3);
	}

	/* 16x16 */
	&--sml {
		width: calc($unit * 4);
		mask-size: calc($unit * 4);
		background-size: calc($unit * 4);
	}

	/* 20x20 */
	&--med {
		width: calc($unit * 5);
		mask-size: calc($unit * 5);
		background-size: calc($unit * 5);
	}

	/* 24x24 */
	&--lrg {
		width: calc($unit * 6);
		mask-size: calc($unit * 6);
		background-size: calc($unit * 6);
	}

	/* 32x32 */
	&--xlrg {
		width: calc($unit * 8);
		mask-size: calc($unit * 8);
		background-size: calc($unit * 8);
	}

	/* List of icons */
	&--chevron {
		&--top {
			background-color: $color-text;
			mask-image: svg-load('../images/chevron-up.svg');

			&--primary {
				background-color: $color-primary;
				mask-image: svg-load('../images/chevron-up.svg');
			}

			&--white {
				background-color: $white;
				mask-image: svg-load('../images/chevron-up.svg');
			}

			&--light {
				background-color: $color-text-light;
				mask-image: svg-load('../images/chevron-up.svg');
			}
		}

		&--right {
			background-image: svg-load('../images/chevron-right.svg');

			&--primary {
				background-color: $color-primary;
				mask-image: svg-load('../images/chevron-right.svg');
			}

			&--light {
				background-color: $color-text-light;
				mask-image: svg-load('../images/chevron-right.svg');
			}
		}

		&--down {
			background-color: $color-text;
			mask-image: svg-load('../images/chevron-down.svg');

			&--primary {
				background-color: $color-primary;
				mask-image: svg-load('../images/chevron-down.svg');
			}

			&--light {
				background-color: $color-text-light;
				mask-image: svg-load('../images/chevron-down.svg');
			}
		}

		&--left {
			background-image: svg-load('../images/chevron-left.svg');

			&--primary {
				background-color: $color-primary;
				mask-image: svg-load('../images/chevron-left.svg');
			}
		}
	}

	&--arrow {
		&--top {
			background-image: svg-load('../images/arrow-up.svg');

			&--success {
				background-color: $success;
				mask-image: svg-load('../images/arrow-up.svg');
			}

			&--light {
				background-color: $color-text-light;
				mask-image: svg-load('../images/arrow-up.svg');
			}
		}

		&--down {
			background-image: svg-load('../images/arrow-down.svg');

			&--error {
				background-color: $error;
				mask-image: svg-load('../images/arrow-down.svg');
			}

			&--light {
				background-color: $color-text-light;
				mask-image: svg-load('../images/arrow-down.svg');
			}
		}
	}

	&--download {
		background-color: $color-text;
		mask-image: svg-load('../images/download.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/download.svg');
		}

		&--white {
			background-color: $white;
			mask-image: svg-load('../images/download.svg');
		}
	}

	&--view {
		background-image: svg-load('../images/view.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/view.svg');
		}

		&--text {
			background-color: $color-text;
			mask-image: svg-load('../images/view.svg');
		}
	}

	&--hide {
		background-image: svg-load('../images/hide.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/hide.svg');
		}

		&--text {
			background-color: $color-text;
			mask-image: svg-load('../images/hide.svg');
		}
	}

	&--settings {
		background-color: $color-text-light;
		mask-image: svg-load('../images/settings.svg');

		&--text {
			background-color: $color-text;
			mask-image: svg-load('../images/settings.svg');
		}
	}

	&--min-view {
		background-color: $color-text-light;
		mask-image: svg-load('../images/min-view.svg');

		&--text {
			background-color: $color-text;
			mask-image: svg-load('../images/min-view.svg');
		}
	}

	&--max-view {
		background-color: $color-text-light;
		mask-image: svg-load('../images/max-view.svg');

		&--text {
			background-color: $color-text;
			mask-image: svg-load('../images/max-view.svg');
		}
	}

	&--expand {
		background-color: $color-text-light;
		mask-image: svg-load('../images/expand.svg');

		&--text {
			background-color: $color-text;
			mask-image: svg-load('../images/expand.svg');
		}
	}

	&--edit {
		background-image: svg-load('../images/edit.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/edit.svg');
		}

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/edit.svg');
		}
	}

	&--clear-value {
		background-image: svg-load('../images/clear-value.svg');
	}

	&--move {
		background-image: svg-load('../images/move.svg');
	}

	&--close {
		background-color: $color-text-light;
		mask-image: svg-load('../images/close.svg');

		&--text {
			background-image: svg-load('../images/close.svg');
		}
	}

	&--delete {
		background-image: svg-load('../images/delete.svg');

		&--light {
			mask-image: svg-load('../images/delete.svg');
			background-color: $color-text-light;
		}

		&--error {
			mask-image: svg-load('../images/delete.svg');
			background-color: $error;
		}
	}

	&--info {
		background-image: svg-load('../images/info.svg');

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/info.svg');
		}

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/info.svg');
		}

		&--white {
			background-color: $white;
			mask-image: svg-load('../images/info.svg');
		}

		&--error {
			background-color: $error;
			mask-image: svg-load('../images/info.svg');
		}

		&--text {
			background-image: svg-load('../images/info.svg', fill: $color-text-regular);
		}

		&--notification {
			background-image: svg-load('../images/info_notification.svg');
		}
	}

	&--add {
		background-image: svg-load('../images/add.svg');

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/add.svg');
		}

		&--white {
			background-color: $white;
			mask-image: svg-load('../images/add.svg');
		}

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/add.svg');
		}
	}

	&--minus {
		background-image: svg-load('../images/minus.svg');

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/minus.svg');
		}
	}

	&--calendar {
		background-image: svg-load('../images/calendar.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/calendar.svg');
		}
	}

	&--more {
		background-image: svg-load('../images/more.svg');

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/more.svg');
		}

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/more.svg');
		}
	}

	&--menu {
		background-image: svg-load('../images/menu.svg');

		&--white {
			background-color: $white;
			mask-image: svg-load('../images/menu.svg');
		}
	}

	&--add-user {
		background-image: svg-load('../images/add-user.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/add-user.svg');
		}
	}

	&--link {
		background-image: svg-load('../images/link.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/link.svg');
		}
	}

	&--refund {
		background-image: svg-load('../images/refund.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/refund.svg');
		}
	}

	&--print {
		background-color: $color-text;
		mask-image: svg-load('../images/print.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/print.svg');
		}
	}

	&--mail {
		background-image: svg-load('../images/mail.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/mail.svg');
		}
	}

	&--error {
		&--white {
			background-color: $white;
			mask-image: svg-load('../images/error.svg');
		}
	}

	&--capture {
		background-image: svg-load('../images/capture.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/capture.svg');
		}
	}

	&--away {
		background-image: svg-load('../images/away.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/away.svg');
		}
	}

	&--search {
		background-image: svg-load('../images/search.svg');
	}

	&--check {
		background-image: svg-load('../images/check.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/check.svg');
		}

		&--success {
			background-color: $success;
			mask-image: svg-load('../images/check.svg');
		}
	}

	&--check-circle {
		background-image: svg-load('../images/check-circle.svg');

		&--white {
			background-color: $white;
			mask-image: svg-load('../images/check-circle.svg');
		}
	}

	&--copy {
		background-image: svg-load('../images/copy.svg');

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/copy.svg');
		}
	}

	&--receipt-print {
		background-image: svg-load('../images/receipt-print.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/receipt-print.svg');
		}
	}

	&--favorite {
		background-image: svg-load('../images/favorite.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/favorite.svg');
		}
	}

	&--favorite-fill {
		background-image: svg-load('../images/favorite-fill.svg');

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/favorite-fill.svg');
		}
	}

	&--singleline {
		background-image: svg-load('../images/singleline.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/singleline.svg');
		}
	}

	&--multiline {
		background-image: svg-load('../images/multiline.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/multiline.svg');
		}
	}

	&--dropdown {
		background-image: svg-load('../images/dropdown.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/dropdown.svg');
		}
	}

	&--radio-button {
		background-image: svg-load('../images/radio-button.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/radio-button.svg');
		}
	}

	&--checkbox {
		background-image: svg-load('../images/checkbox.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/checkbox.svg');
		}
	}

	&--reload {
		background-image: svg-load('../images/reload.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/reload.svg');
		}
	}

	&--code {
		background-image: svg-load('../images/code.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/code.svg');
		}
	}

	&--contact-us {
		background-image: svg-load('../images/contact-us.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/contact-us.svg');
		}
	}

	&--play-control {
		background-image: svg-load('../images/play-control.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/play-control.svg');
		}
	}

	&--microphone {
		background-image: svg-load('../images/microphone.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/microphone.svg');
		}
	}

	&--reports {
		background-image: svg-load('../images/reports.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/reports.svg');
		}
	}

	&--first-page {
		background-image: svg-load('../images/first-page.svg');
	}

	&--last-page {
		background-image: svg-load('../images/last-page.svg');
	}

	&--upload {
		background-image: svg-load('../images/upload.svg');

		&--white {
			background-color: $white;
			mask-image: svg-load('../images/upload.svg');
		}

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/upload.svg');
		}

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/upload.svg');
		}
	}

	&--customers {
		background-image: svg-load('../images/customers.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/customers.svg');
		}
	}

	&--payment {
		background-image: svg-load('../images/payment.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/payment.svg');
		}
	}

	&--save {
		background-image: svg-load('../images/save.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/save.svg');
		}

		&--primary {
			background-color: $color-primary;
			mask-image: svg-load('../images/save.svg');
		}
	}

	&--card {
		background-image: svg-load('../images/card.svg');

		&--light {
			background-color: $color-text-light;
			mask-image: svg-load('../images/card.svg');
		}
	}

	&--burgger {
		background-image: svg-load('../images/burgger.svg');
	}

	&--launch-user {
		background-image: svg-load('../images/launch-user.svg');

		&--light {
			mask-image: svg-load('../images/launch-user.svg');
			background-color: $color-text-light;
		}
	}

	&--drop-in {
		background-image: svg-load('../images/drop-in.svg');
	}

	&--document {
		background-image: svg-load('../images/document.svg');
	}

	&--management {
		background-image: svg-load('../images/management.svg');
	}

	&--dashboard {
		background-image: svg-load('../images/dashboard.svg');
	}

	&--logout {
		background-image: svg-load('../images/logout.svg');
	}

	/* Use regular icons for sizes smaller than 12px to ensure readability. */
	&--regular {
		&--info {
			background-image: svg-load('../images/regular/info.svg');
		}

		&--error {
			background-image: svg-load('../images/regular/error.svg');
		}

		&--warning {
			background-image: svg-load('../images/regular/warning.svg');
		}

		&--check {
			background-image: svg-load('../images/regular/check.svg');

			&--primary {
				background-image: svg-load('../images/regular/check-primary.svg');
			}
		}
	}

	/* Older icons waiting to be updated */
	&--open-new {
		background-color: $color-primary;
		mask-image: url('data:image/svg+xml,<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.36847 2C1.81618 2 1.36847 2.44772 1.36847 3V20.9999C1.36847 21.5522 1.81618 21.9999 2.36847 21.9999H20.3684C20.9207 21.9999 21.3684 21.5522 21.3684 20.9999V15.6562C21.3684 15.1039 20.9207 14.6562 20.3684 14.6562C19.8161 14.6562 19.3684 15.1039 19.3684 15.6562V19.9999H3.36847V4H7.99331C8.5456 4 8.99331 3.55228 8.99331 3C8.99331 2.44772 8.5456 2 7.99331 2H2.36847ZM13.6185 2C13.0662 2 12.6185 2.44772 12.6185 3C12.6185 3.55228 13.0662 4 13.6185 4H17.9542L10.0989 11.8554C9.70834 12.2459 9.70834 12.879 10.0989 13.2696C10.4894 13.6601 11.1226 13.6601 11.5131 13.2696L19.3684 5.41424V9.74997C19.3684 10.3023 19.8161 10.75 20.3684 10.75C20.9207 10.75 21.3684 10.3023 21.3684 9.74997V3.00814L21.3684 3C21.3684 2.44772 20.9207 2 20.3684 2H20.3684H13.6185Z" fill="black" /></svg>');
	}

	&--transactions {
		background-image: svg-load('../images/transactions.svg', fill: $grey-10);
	}

	&--reset {
		background-image: svg-load('../images/reset.svg', fill: $color-text);
	}
}
