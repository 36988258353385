/*------------------------------------*\
    # modules.table
\*------------------------------------*/

.table {
	display: table;
	width: 100%;
	border-collapse: collapse;

	&:has(.table--emptystate) {
		height: 100%;
	}

	/* Use when wrapper is needed */
	&__wrapper {
		flex-grow: 1;
		overflow-y: auto;
	}

	/* This is the same styling as plugin table */
	&--primary {
		th {
			padding: calc($unit * 3);

			font-size: calc($type-nano * 1px);
			line-height: calc($unit * 3);
			font-weight: $type-weight-semibold;
			text-transform: uppercase;
			letter-spacing: 0.1px;
			text-align: left;
			white-space: nowrap;
			color: $color-text-light;

			background-color: $color-navigation;

			&:first-child {
				border-radius: calc($global-radial / 2) 0 0 calc($global-radial / 2);
			}

			&:last-child {
				border-radius: 0 calc($global-radial / 2) calc($global-radial / 2) 0;
			}

			/* Alignment */
			&.center {
				text-align: center;
			}

			&.right {
				text-align: right;
			}

			/* Sticky options */
			&.sticky-top {
				position: sticky;
				top: 0;
			}

			/* Sorting icon */
			.sort-icon {
				width: calc($unit * 2);
				height: calc($unit * 2);
				mask-size: calc($unit * 2);
			}
		}

		tr {
			&:hover {
				background-color: #f7f9fb;
			}

			&:last-child {
				td {
					border-bottom: 0;
				}
			}

			td {
				height: calc($unit * 13);
				padding: 0 calc($unit * 3);
				border-bottom: 1px solid $grey-10;

				&:has(.table--emptystate) {
					padding: 0;
				}

				&.center {
					text-align: center;
				}
			}

			&.has-subrow {
				& > td {
					border-bottom: 0;
					padding-bottom: 0;
				}
			}
		}

		/* Empty state */
		.react-grid-Empty {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
			width: 100% !important;

			background: linear-gradient(180deg, #ffffff 33.44%, #f6f8fa 100%);

			.react-grid-Empty-img {
				flex-shrink: 0;
				width: 130px;
				margin-left: calc($unit * 10);

				aspect-ratio: 1;
				margin-bottom: calc($unit * 6);
				background-image: url('../../img/emptystate.png');
				background-size: 100%;
			}

			.react-grid-Empty-title {
				margin-bottom: calc($unit * 2);
				padding-left: calc($unit * 10);
				@mixin type-scale $type-large, 1.25;
				font-weight: $type-weight-medium;
			}

			.react-grid-Empty-text {
				padding-left: calc($unit * 10);
				color: $color-text-light;
			}
		}

		/* Customs */
		&--card-row {
			border-collapse: separate;
			border-spacing: 0;

			.equipment-card__thumbnail {
				width: calc($unit * 13);
				aspect-ratio: 1;
				padding-top: 0;
				margin: calc($unit * 2) 0;

				&:before,
				&:after {
					display: none;
				}
			}

			th {
				&:first-child {
					padding-left: calc($unit * 6);
				}
			}

			tr {
				&:hover {
					background-color: $white;
				}

				&:not(.table--primary--card-row__separator) {
					&.is-expanded {
						td {
							z-index: 1;
							position: relative;
							border-bottom: 0;

							&:first-child {
								border-radius: calc($global-radial * 1.5) 0 0 0;
							}

							&:last-child {
								border-radius: 0 calc($global-radial * 1.5) 0 0;
							}
						}
					}

					&.subrow {
						td {
							&:first-child,
							&:last-child {
								position: relative;

								padding-left: calc($unit * 6);

								border-top: 0;
								border-radius: 0 0 calc($global-radial * 1.5) calc($global-radial * 1.5);
								box-shadow: $shadow-big;

								&:before {
									content: '';

									position: absolute;
									top: 0;
									left: calc($unit * 3);
									right: calc($unit * 3);

									display: block;
									height: 1px;

									background-color: $grey-10;
								}
							}
						}
					}

					td {
						border-top: 1px solid $grey-10;
						border-bottom: 1px solid $grey-10;

						&:first-child {
							padding-left: calc($unit * 6);
							border-left: 1px solid $grey-10;
							border-radius: calc($global-radial * 1.5) 0 0 calc($global-radial * 1.5);
						}

						&:last-child {
							border-right: 1px solid $grey-10;
							border-radius: 0 calc($global-radial * 1.5) calc($global-radial * 1.5) 0;
						}

						@media (--screen-to-med) {
							&:first-child {
								padding-left: calc($unit * 3);
							}
						}

						&.fixed {
							position: sticky;
							right: 0;
						}
					}
				}

				&.subrow {
					& > td {
						padding-top: calc($unit * 4);
						padding-bottom: calc($unit * 6);
						font-size: calc($type-plus-small * 1px);
						line-height: calc($unit * 5);
						font-weight: $type-weight-regular;
						color: $color-text-light;
					}
				}
			}

			&__separator {
				td {
					&:first-child,
					&:last-child {
						border: 0;
						height: calc($unit * 4);
					}
				}
			}

			&__wrapper {
				width: 100%;
				overflow: auto hidden;
			}
		}
	}

	/* Used for uploaded items */
	&--secondary {
		color: $color-text-dark;

		& > thead {
			& > tr {
				& > th {
					position: relative;

					padding: 0 calc($unit * 2);
					white-space: nowrap;

					font-size: calc($type-plus-small * 1px);
					line-height: calc($unit * 8);
					font-weight: $type-weight-medium;
					text-align: left;

					border-top: 1px solid $grey-10;
					border-bottom: 1px solid $grey-10;
					background-color: $white;

					@media (--screen-from-med) {
						padding: 0 calc($unit * 4);
					}

					&.sticky {
						position: sticky;
						top: -1px;
						z-index: 10;
					}
				}
			}
		}

		& > tbody {
			&:empty {
				position: relative;
				height: calc($unit * 7.5);

				&:before {
					content: 'No items';

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					display: block;
					width: 100%;
					padding: calc($unit * 2);

					@mixin type-scale $type-tiny, 1;
					text-align: center;
					color: $color-text-light;

					background-color: $grey-0;
				}
			}

			& > tr {
				&.is-highlighted {
					& > td {
						position: relative;
						border-color: $color-primary;

						&:before {
							content: '';

							position: absolute;
							top: 0;
							right: 0;
							left: 0;

							display: block;
							height: 1px;
							background-color: $color-primary;
						}
					}
				}

				&:not(:nth-child(odd)) {
					& > td {
						background-color: $grey-0;
					}
				}

				& > td {
					height: calc($unit * 9.5);
					padding: calc($unit / 2) calc($unit * 2);
					vertical-align: middle;

					font-size: calc($type-plus-small * 1px);
					line-height: calc($unit * 2.5);

					border-top: 1px solid transparent;
					border-bottom: 1px solid $grey-10;

					@media (--screen-from-med) {
						padding: 0 calc($unit * 4);
					}
				}
			}
		}
	}

	/* Empty state */
	&--emptystate {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100% !important;
		height: 100%;

		background: linear-gradient(180deg, #ffffff 33.44%, #f6f8fa 100%);

		&--img {
			width: 160px;
			height: 160px;
			margin-bottom: calc($unit * 6);
			background-image: url('../../img/emptystate.png');
			background-size: 160px;
		}

		&--title {
			margin-bottom: calc($unit * 2);
			@mixin type-scale $type-large, 1.25;
			font-weight: $type-weight-medium;
		}

		&--text {
			color: $color-text-light;
		}
	}

	/* Use this on tables with draggable rows */
	&--fixed {
		table-layout: fixed;
	}

	/* Customs */
	&--plans {
		overflow: hidden;
		border: 0;
		border-bottom: 1px solid $grey-10;

		&__header {
			padding: calc($unit * 6) calc($unit * 4);

			font-size: calc($type-base * 1px);
			font-weight: $type-weight-medium;
			gap: calc($unit * 4);
			text-align: left;

			background-color: $grey-10;
			border: 1px solid $grey-10;

			label {
				font-size: calc($type-base * 1px);
				font-weight: $type-weight-medium;
				gap: calc($unit * 4);
			}

			&.is-selected {
				color: white;
				background-color: $grey-100;

				label {
					color: white;
				}
			}

			&.sticky {
				position: sticky;
				top: 0;
			}
		}

		&__subheader {
			background-color: $color-navigation;

			& > th {
				padding: calc($unit * 3.5) calc($unit * 4);

				font-size: calc($type-nano * 1px);
				line-height: calc($unit * 3);
				font-weight: $type-weight-semibold;
				text-align: left;
				text-transform: uppercase;
				letter-spacing: 0.05px;
				color: $grey-60;
			}
		}

		&__list {
			display: flex;
			align-items: flex-start;
			gap: calc($unit * 6);
			overflow-x: auto;
			margin-right: calc(-$unit * 4);
			padding-bottom: calc($unit * 4);

			@media (--screen-from-med) {
				margin-right: calc(-$unit * 8);
			}
		}

		&__wrapper {
			flex-shrink: 0;
			overflow: auto;
			border: 1px solid $grey-10;
			border-bottom: 0;
			border-radius: calc($global-radial * 1.5);

			&--plans {
				width: 420px;

				&:last-child {
					margin-right: calc($unit * 8);
				}

				td {
					&:last-child {
						border-right: 0;
					}
				}
			}
		}

		td {
			min-width: 100px;
			padding: calc($unit * 3.5) calc($unit * 4);

			font-size: calc($type-xsmall * 1px);
			line-height: calc($unit * 4);
			font-weight: $type-weight-medium;

			border-right: 1px solid $grey-10;
			border-bottom: 1px solid $grey-10;

			&.action-cell {
				text-align: center;
				border-right: 0;

				@media (--screen-to-lrg) {
					display: none;
				}
			}
		}
	}

	&--accessories {
		&__filter {
			overflow: hidden;
			padding: calc($unit * 1.5) calc($unit * 3);
			padding-bottom: 0;
			margin-bottom: calc($unit * 3);

			background-color: $grey-0;
		}

		&__list {
			margin-bottom: calc($unit * 10);

			&__header {
				padding: $unit calc($unit * 3);
				margin-bottom: calc($unit * 2);

				@mixin type-scale $type-small-alt, 1;
				font-weight: $type-weight-bold;
			}

			&__item {
				padding: 0;

				/* Add and Added custom checkbox buttons */
				&__add-check {
					padding: calc($unit * 2);
					width: 89px;
					border-radius: $global-rounded;
					transition: all 250ms ease-in-out;

					&:hover {
						background-color: $grey-0;
					}

					label {
						white-space: nowrap;
						font-size: calc($unit * 2);
					}
				}

				&__added-check {
					padding: calc($unit * 2);
					background-color: $color-primary;
					border-radius: $global-rounded;

					label {
						color: #ffffff;
						font-weight: $type-weight-semibold;
						white-space: nowrap;
						font-size: calc($unit * 2);
					}

					.input--check:checked + label:before {
						border: 2px solid #ffffff;
					}
				}

				&__quantity {
					width: 116px;

					&__wider {
						width: 140px;
					}
				}

				&--expanded {
					background-color: $grey-0;
				}
			}

			&__thumbnail {
				margin-right: calc($unit * 3);
				height: 52px;
				min-width: 52px;

				border: 1px solid $grey-10;
				border-radius: $global-radial;
				background-size: 52px;
				background-repeat: no-repeat;
			}

			&__group {
				overflow-x: auto;

				& > div:not(:first-child) {
					.equipment__list__th,
					.equipment__list__item.case-option:before {
						display: none;
					}
				}
			}
		}

		/* List wrapping for Accessories on Merchant page */
		&--merchant {
			.equipment__list {
				@media (min-width: 1200px) and (max-width: 1800px) {
					flex-wrap: wrap;
					padding-right: 140px;
				}

				&__item {
					@media (--screen-from-sml) {
						flex: 0 0 50%;
					}

					@media (--screen-from-med) {
						flex: 0 0 33.33%;
					}

					@media (min-width: 1520px) and (max-width: 1800px) {
						flex: 1;

						&.basis-140 {
							flex: 1 0 140px;
						}
					}

					@media (min-width: 1520px) and (max-width: 1800px) {
						&.select {
							position: absolute;
							top: 50%;
							right: $unit;
							transform: translateY(-50%);

							.equipment__list__th {
								display: none;
							}
						}

						&.name,
						&.case-option,
						&.total-price,
						&.retail-price,
						&.merchant-price,
						&.quantity,
						&.basis-140,
						&.note-column {
							flex: 0 0 33.33%;
						}
					}
				}

				&__td {
					@media (min-width: 1520px) and (max-width: 1800px) {
						padding: $unit;
					}
				}
			}

			.accessories__list__group {
				@media (max-width: 1800px) {
					box-shadow: none;
				}

				& > div {
					@media (max-width: 1800px) {
						margin-bottom: calc($unit * 2);
						box-shadow: $shadow-big;

						.equipment__list__item {
							&:not(:first-child) {
								.equipment__list__th {
									display: block;
								}
							}
						}
					}

					&:nth-child(even) {
						@media (max-width: 1800px) {
							background-color: white;
						}
					}
				}
			}
		}
	}

	&--saved-equipment {
		width: 100%;

		& > thead {
			& > tr {
				& > th {
					z-index: 1;
					position: sticky;
					top: 0;
					padding: calc($unit * 3) calc($unit * 4);

					font-size: calc($type-nano * 1px);
					line-height: calc($unit * 3);
					font-weight: $type-weight-semibold;
					letter-spacing: 0.5px;
					text-transform: uppercase;
					color: $color-text-light;

					border-bottom: 1px solid $grey-10;
					background-color: $white;
				}
			}
		}

		& > tbody {
			& > tr {
				& > td {
					padding: calc($unit * 3) calc($unit * 4);
					font-size: calc($type-xsmall * 1px);
					line-height: calc($unit * 4);
					border-bottom: 1px solid $grey-10;
				}
			}

			&:empty {
				position: relative;
				height: calc($unit * 10);

				&:after {
					content: 'No Items';

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					display: block;
					margin-top: calc($unit * 2);

					text-align: center;
					color: $color-text-light;
				}
			}
		}

		&__thumbnail {
			width: calc($unit * 6);
			height: calc($unit * 6);

			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			border: 1px solid $grey-10;
			border-radius: calc($global-radial / 2);
		}
	}

	&--chart {
		&__thumbnail {
			width: calc($unit * 14);
			height: calc($unit * 14);

			border: 1px solid $grey-10;
			border-radius: calc($global-radial / 2);

			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}
}
