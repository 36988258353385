/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.display {
	&--f {
		display: flex;
	}

	&--b {
		display: block;
	}

	&--ib {
		display: inline-block;
	}

	&--if {
		display: inline-flex;
	}

	&--n {
		display: none;
	}
}

.hide {
	&--from {
		&--lrg {
			@media (--screen-from-lrg) {
				display: none !important;
			}
		}
	}
}
