/*------------------------------------*\
    # plugins.rc-timepicker
\*------------------------------------*/

.react-time-picker__wrapper {
	width: 100%;
	padding-left: $unit;
	border: none !important;
}

.react-time-picker__inputGroup__input {
	outline: none;
	-webkit-appearance: none;
}

.react-time-picker--open {
    border-color: $color-primary;
	
	&:hover {
		border-color: $color-primary;
	}
}

select.react-time-picker__inputGroup__input {
	flex-grow: 2;
}