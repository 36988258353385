/*------------------------------------*\
    # modules.modal
\*------------------------------------*/

.modal {
	/* Background */
	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 999999;

		border: 0;
		background-color: color($color-modal-overlay a(0.9));

		/* Position modal with flex */
		&--flex {
			display: flex;
			align-items: center;
			justify-content: center;

			@media (--screen-to-med) {
				align-items: flex-end;
			}

			& > .modal__content {
				position: relative;
				top: unset;
				left: unset;
				transform: translate(0);
			}
		}
	}

	/* Main wrapper */
	&__content {
		position: absolute;
		left: 50%;
		z-index: 9999999;
		transform: translateX(-50%);

		min-width: 292px;
		width: 100%;

		@media (--screen-to-med) {
			bottom: 0;
		}

		@media (--screen-from-med) {
			top: 50%;
			transform: translate(-50%, -50%);
			width: 578px;
		}

		&:focus {
			outline: none;
		}

		/* Custom scroll */
		*:not(textarea)::-webkit-scrollbar-button {
			height: calc($unit * 5);
		}

		&:has(.modal__content--link-payment) {
			width: 1080px;
		}

		&:has(.modal--new-transaction.is-expanded) {
			width: 1120px;
		}

		&--gradient-bg {
			& > .modal__content__wrapper {
				background: linear-gradient(179deg, #f1f5f8 0, rgba(255, 255, 255) 214px);
			}
		}

		&--privacy {
			.modal__body {
				@mixin type-scale $type-small, 1;
			}
		}

		&__wrapper {
			overflow: hidden auto;
			background-color: $white;

			@media (--screen-to-med) {
				max-height: 100vh;
				border-radius: calc($global-radial * 2) calc($global-radial * 2) 0 0;
			}

			@media (--screen-from-med) {
				max-height: calc(100vh - 100px);
				border-radius: calc($global-radial * 2);
			}

			&:has(.modal--new-transaction:not(.is-expanded)) {
				background: linear-gradient(179deg, #f1f5f8 0, rgba(255, 255, 255) 214px);
			}

			&:has(.loader__holder) {
				& + .modal__close {
					display: none;
				}
			}

			& > .auth__form {
				max-width: 100%;
				padding: calc($unit * 6) calc($unit * 8);
			}

			& > .auth__main__privacy {
				display: none;
			}
		}
	}

	/* Main Modal's elements */
	&__header {
		z-index: 9;

		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: calc($unit * 2);
		padding: calc($unit * 4.5);
		padding-bottom: 0;

		@media (--screen-from-med) {
			padding: calc($unit * 8);
			padding-bottom: 0;
		}

		&:empty {
			display: none;
		}

		&__title {
			@mixin type-scale $type-xlarge, 1.5;
			font-weight: $type-weight-medium;
		}
	}

	&__body {
		padding: calc($unit * 6) calc($unit * 4.5);
		outline: none;

		.loader--progress {
			top: 0;
		}

		/* Reset default bottom margin for react-grid component in the modal */
		.react-grid {
			margin-bottom: 0;
		}

		&:has(.loader--reload) {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 180px;
		}

		&:has(+ .modal__footer > .equipment__footer) {
			padding-bottom: 150px;
		}

		@media (--screen-from-med) {
			padding: calc($unit * 6) calc($unit * 8);
		}

		@media (--screen-to-med) {
			padding-bottom: 96px;
		}

		/* Customs */
		&--info-panel {
			padding-inline: 0;

			.info-panel__heading__wrapper,
			.info-panel__section {
				padding-inline: calc($unit * 4.5);

				@media (--screen-from-med) {
					padding-inline: calc($unit * 8);
				}
			}

			&--section {
				padding-inline: calc($unit * 4.5);

				@media (--screen-from-med) {
					padding-inline: calc($unit * 8);
				}
			}
		}
	}

	&__footer {
		z-index: 9999;
		bottom: 0;

		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		gap: calc($unit * 3);
		height: 72px;
		padding: calc($unit * 4) calc($unit * 4.5);

		background-color: $color-navigation;

		@media (--screen-to-med) {
			position: absolute;
			right: 0;
			left: 0;
		}

		@media (--screen-from-med) {
			position: sticky;
			padding: calc($unit * 4) calc($unit * 8);
		}

		&:has(.equipment__footer) {
			height: auto;
		}
	}

	/* Close button */
	&__close {
		z-index: 9999;
		position: absolute;
		bottom: calc($unit * 4);
		left: calc($unit * 4.5);

		@media (--screen-from-med) {
			left: calc($unit * 8);
		}
	}

	/* 
	Modal sizes
	*/
	&--lrg {
		max-width: 844px;
		width: 100%;
	}

	/*
	Custom Modals
	*/
	&--new-lead {
		.leads {
			width: 100%;
			padding-bottom: 0;

			.application-type {
				padding: 0 calc($unit * 4.5);
				padding-top: calc($unit * 6);

				@media (--screen-from-med) {
					padding: calc($unit * 8);
				}
			}
		}

		.card {
			border: 0;
			margin-bottom: 0;

			&.is-expanded {
				box-shadow: none;
			}

			&:has(.card__header) {
				& > .card__body {
					padding-top: calc($unit * 6);
				}
			}

			& > .card__header {
				padding: calc($unit * 2.5) calc($unit * 4.5);
				background-color: $color-navigation;

				@media (--screen-from-med) {
					padding: calc($unit * 2.5) calc($unit * 8);
				}

				& > h5 {
					font-size: calc($type-nano * 1px);
					line-height: calc($unit * 3);
					font-weight: $type-weight-semibold;
					letter-spacing: 0.5px;
					text-transform: uppercase;
					color: $color-text-light;
				}
			}

			& > .card__body {
				padding: calc($unit * 6) calc($unit * 4.5);

				@media (--screen-from-med) {
					padding: calc($unit * 6) calc($unit * 8);
				}
			}
		}

		.modal__close {
			@media (--screen-to-med) {
				top: calc($unit * 4);
				right: calc($unit * 6);
				left: auto;

				width: calc($unit * 10);
				height: calc($unit * 10);

				color: transparent;

				border: 1px solid $grey-10;
				background-color: $white;

				&:before {
					content: '';

					position: absolute;

					display: inline-flex;
					width: calc($unit * 4);
					aspect-ratio: 1;

					background-size: calc($unit * 4);
					background-image: svg-load('../images/close.svg');
				}
			}
		}

		.modal__footer {
			position: sticky;
		}
	}

	&--accessories-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: calc($unit * 6);
	}
}
