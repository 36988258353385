/*------------------------------------*\
    # components.sidebar
\*------------------------------------*/

.sidebar {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-bottom: calc($unit * 6);

	&__header {
		position: sticky;
		top: 0;
		z-index: 3;

		display: flex;
		padding: calc($unit * 6.5) calc($unit * 5);
		align-items: center;
		flex-shrink: 0;

		border-radius: $global-radial;
		background-color: $color-navigation;
		transition: all 250ms ease-in-out;

		@media (--screen-from-xlrg) {
			display: flex;
			height: 88px;
		}

		&.is-collapsed {
			height: auto;
			padding: calc($unit * 6) calc($unit * 2);

			& > .sidebar__header__logo {
				width: 100%;
				height: 40px;
				background-image: svg-load('../../img/sola-logo-short.svg');
			}
		}

		&__logo {
			position: relative;

			display: inline-block;
			width: 100px;
			height: 24px;

			color: transparent;

			background-image: svg-load('../../img/sola-logo.svg');
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			&:hover {
				color: transparent;
			}
		}
	}

	&__account {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		padding: 0 calc($unit * 4);
		margin-bottom: calc($unit * 4);

		& > .header__account {
			pointer-events: none;
		}

		&__logout {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			width: calc($unit * 10);
			height: calc($unit * 10);

			background-color: transparent;
			border: 0;
			border-radius: $global-rounded;

			cursor: pointer;
			transition: all 250ms ease-in-out;

			&:hover {
				background-color: color($color-navigation blackness(+5%));
			}
		}
	}

	&__footer {
		position: relative;

		display: flex;
		flex-direction: column;
		padding: calc($unit * 8) calc($unit * 2) calc($unit * 6);
		margin-top: auto;

		font-size: calc($type-xsmall * 1px);
		line-height: calc($unit * 4);
		text-align: center;
		color: $grey-50;

		&.is-collapsed {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc($unit * 9);
			height: calc($unit * 9);
			padding: 0;
			margin: auto;
			margin-bottom: calc($unit * 6);

			border-radius: $global-rounded 0 0 $global-rounded;

			&:hover {
				background-color: $grey-10;

				& > .sidebar__footer__privacy {
					display: block;
				}
			}
		}

		&__privacy {
			&.is-collapsed {
				position: absolute;
				top: 0;
				left: 100%;

				display: none;
				padding: calc($unit / 2) calc($unit * 3);
				white-space: nowrap;

				font-weight: $type-weight-medium;
				color: $grey-80;

				border-radius: 0 $global-radial $global-radial 0;
				background-color: $grey-10;
			}
		}
	}
}
