/*------------------------------------*\
    # plugins.rdp
\*------------------------------------*/

.rdp {
	margin: 0 !important;

	/* Variable overide */
	--rdp-cell-size: calc($unit * 8) !important;

	& > .rdp-months {
		@media (--screen-to-med) {
			display: block;
		}

		& > .rdp-month {
			margin: 0;

			&.rdp-caption_start,
			&.rdp-caption_end {
				padding-bottom: calc($unit * 6);
			}

			&.rdp-caption_start {
				@media (--screen-from-med) {
					border-right: 1px solid $grey-10;
					padding-right: 56px;
				}
			}

			&.rdp-caption_end {
				@media (--screen-from-med) {
					padding-left: 56px;
				}
			}

			/* Header */
			& > .rdp-caption {
				height: calc($unit * 10);
				margin-bottom: calc($unit * 4);
				line-height: calc($unit * 10);

				/* Month name */
				& > .rdp-caption_label {
					margin: 0;
					padding: 0;

					@mixin type-scale $type-base, 1;
					font-weight: $type-weight-medium;
					color: $color-text;

					border: 0;
				}

				/* Next and Prev nav buttons */
				& > .rdp-nav {
					display: flex;
					justify-content: space-between;
					width: 100%;

					& > .rdp-nav_button {
						display: flex;
						align-items: center;
						justify-content: center;
						width: calc($unit * 10);
						height: calc($unit * 10);

						border: 1px solid $grey-10;
						border-radius: $global-rounded;
						background-repeat: no-repeat;
						background-position: center;
						background-size: calc($unit * 4);
						transition: all 0.2s ease;

						aspect-ratio: 1;

						&:hover {
							background-color: white;
							border-color: $grey-50;
						}

						& > svg {
							display: none;
						}

						&.rdp-nav_button_previous {
							background-image: svg-load('../images/chevron-left.svg');
						}

						&.rdp-nav_button_next {
							background-image: svg-load('../images/chevron-right.svg');
						}
					}
				}
			}

			/* Table */
			& > .rdp-table {
				@media (--screen-to-med) {
					width: 100%;
					max-width: 100%;
				}
				/* Table header */
				& > .rdp-head {
					& > .rdp-head_row {
						& > .rdp-head_cell {
							font-size: calc($type-plus-small * 1px);
							line-height: calc($unit * 5);
							font-weight: $type-weight-medium;
							text-transform: capitalize;
							color: $grey-30;

							&:first-child,
							&:last-child {
								color: $color-primary;
							}
						}
					}
				}

				/* Table body */
				& > .rdp-tbody {
					& > .rdp-row {
						& > .rdp-cell {
							/* General cell styling */
							& > .rdp-button {
								color: $grey-60;
								border-radius: calc($global-radial / 2);

								&:hover {
									background-color: $grey-10;
								}

								@media (--screen-to-med) {
									width: 100%;
									max-width: 100%;
									height: calc($unit * 10);
								}
							}

							/* Weekend days */
							&:first-child,
							&:last-child {
								& > .rdp-button {
									&:not(.rdp-day_range_start) {
										color: $color-primary;
									}
								}
							}

							/* Start range */
							& > .rdp-day_range_start,
							& > .rdp-day_range_end {
								color: $white;
								background-color: $color-secondary;

								&:hover {
									background-color: color($color-secondary lightness(20%));
								}
							}

							& > .rdp-day_range_middle {
								color: $grey-50;
								background-color: $ice-blue;
							}

							& > .rdp-day_today {
								font-weight: $type-weight-medium;
								border: 1px solid $grey-60;
							}
						}
					}
				}
			}
		}
	}

	&__wrapper {
		position: relative;
		padding: calc($unit * 6) calc($unit * 8) 0;
		background-color: $white;

		@media (--screen-from-med) {
			padding: calc($unit * 10) calc($unit * 16) 0;
		}
	}

	&__date,
	&__time {
		@media (--screen-from-med) {
			display: grid;
			grid-template-columns: 1fr auto 1fr;
		}

		&__separator {
			@media (--screen-from-med) {
				width: 1px;
				height: 100%;
				margin: 0 56px;
				background-color: $grey-10;
			}
		}
	}

	&__footer {
		position: sticky;
		bottom: 0;
		z-index: 1;

		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: calc($unit * 3);
		padding: calc($unit * 3) calc($unit * 6);
		margin-right: calc(-$unit * 8);
		margin-left: calc(-$unit * 8);
		margin-top: calc($unit * 8);

		background-color: $color-navigation;

		@media (--screen-from-med) {
			padding: calc($unit * 4) calc($unit * 10);
			margin-right: -64px;
			margin-left: -64px;
		}
	}

	&--single {
		& > .rdp-months {
			justify-content: center;

			& > .rdp-month {
				border-right: 0 !important;
				padding-bottom: 0 !important;

				& > .rdp-caption {
					position: relative;
					justify-content: center;

					& > .rdp-nav {
						position: absolute;
						top: 0;
						right: 0;
						left: 0;

						display: flex;
						justify-content: space-between;
					}
				}

				& > .rdp-table {
					& > .rdp-tbody {
						& > .rdp-row {
							& > .rdp-cell {
								& > .rdp-button {
									&.rdp-day_selected {
										color: $white;

										&:hover {
											background-color: color($color-primary blackness(-10%));
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.rc-menu {
	&:has(.rdp__wrapper) {
		display: flex;
		padding: 0 !important;
		margin: 0 !important;

		.rc-menu-item {
			padding: 0 !important;
			margin: 0 !important;
		}
	}
}
