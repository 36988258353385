/*------------------------------------*\
    # components.footer
\*------------------------------------*/

.footer {
	display: none;
	height: 30px;
	background-color: #fff;
	color: $color-text-light;
	overflow: hidden;

	@media (--screen-height-from-sml) {
		display: block;
	}

	@media (--screen-from-sml) {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
	}

	@media (--screen-from-lrg) {
		height: $footer-height;
		padding-left: calc($unit * 8);
	}

	@media (--screen-from-xlrg) {
		left: $aside-width-sml;
		padding-left: calc($unit * 5);
	}

	@media (--screen-from-xxlrg) {
		left: $aside-width;
	}

	&.sidebar-is-visible {
		transition: left 250ms ease-in-out;

		@media (--screen-from-xxlrg) {
			left: calc($aside-width + $aside-width);
		}
	}

	&.is-collapsed {
		transition: left 250ms ease-in-out;

		@media (--screen-from-xxlrg) {
			left: $aside-width-collapsed;
		}

		&.sidebar-is-visible {
			@media (--screen-from-xxlrg) {
				left: calc($aside-width-collapsed + $aside-width);
			}
		}
	}
}
